import { combineReducers } from "@reduxjs/toolkit";
import { authOtpViaEmailReducer } from "./auth/authOtpViaEmail";
import { authOtpViaSMSReducer } from "./auth/authOtpViaSMS";
import { currentDealorReducer } from "./auth/currentDealor";
import { dealorShipLocationsReducer } from "./auth/dealorShipLocations";
import { forgetPasswordViaEmailReducer } from "./auth/forgetPasswordViaEmail";
import { forgetPasswordViaSMSReducer } from "./auth/forgetPasswordViaSMS";
import { loginReducer } from "./auth/login";
import { resetPasswordReducer } from "./auth/resetPassword";
import { resetPasswordVerifyOtpReducer } from "./auth/resetPasswordVerifyOtp";
import { updateDealorReducer } from "./auth/updateDealor";
import { verifyAuthOtpReducer } from "./auth/verifyAuthOtp";
import { billInvoicesReducer } from "./orders/billInvoices";
import { dispatchStatusCountReducer } from "./orders/dispatchStatusCount";
import { notificationCountReducer } from "./orders/notificationCount";
import { orderDetailsReducer } from "./orders/orderDetails";
import { ordersReducer } from "./orders/orders";
import { ordersCountReducer } from "./orders/ordersCount";
import { pendingOrdersCountReducer } from "./orders/pendingOrdersCount";
import { placeOrderReducer } from "./orders/placeOrder";
import { uploadInvoiceReducer } from "./orders/uploadInvoice";
import { prodcutCRUDReducer } from "./products/productCRUD";
import { productsReducer } from "./products/products";
import {addApplicantReducer} from "./dealorOnBoarding/addApplicant"
import {getApplicantSliceReducer} from "./dealorOnBoarding/getApplicant"
import { getEmailTempleteReducer } from "./dealorOnBoarding/emailTemplete";
import {addKinUserReducer} from "./kinUser/addKinApi";
import {getKinUserSliceReducer} from "./kinUser/getKinUser";
import { addInnoceansUserReducer } from "./innoceanUser/addInnoceansUser";
import { getInnoceansUserSliceReducer } from "./innoceanUser/getInnoceanUser";
import { getGroupDealorSliceReducer } from "./dealerGroup/getGroupDealer";
import { addDealerGroupReducer } from "./dealerGroup/addGroup";
import { getCitiesReducer } from "./cities/cities";
import { getGroupOutletSliceReducer } from "./dealerGroup/getGroupOutlet";
import { getSupplierUserSliceReducer } from "./supplierUser/getSupplierUser";
import { addSupplierUserReducer } from "./supplierUser/addSupplierUser";
import { addDealerGroupOutletReducer } from "./dealerGroup/addOutlet";
import addInviteReducer from "./dealerGroup/addInvite";
import { getInviteReducer, getInviteSliceReducer } from "./dealerGroup/getInvite";
import { copyEmailTemplateReducer } from "./dealerGroup/copyEmailTemplete";
import { bulkOutletUploadReducer } from "./dealerGroup/bulkOutletUpload";
import { getUserTypeReducer } from "./rolesAcessManagement/getUserType";
import { addRoleSliceReducer } from "./rolesAcessManagement/addRole";
import { getUserRoleReducer } from "./rolesAcessManagement/getRoles";
import { getUserDetailReducer } from "./userProfile/getUserDetail";
import { editUserDetailReducer } from "./userProfile/editUserDetail";
import { getEmailTempleteIdReducer } from "./dealerGroup/getEmailTempleteId";
import { getRegionForOutletReducer } from "./dealerGroup/getRegionForOutlet";
import { getStateForOutletReducer } from "./dealerGroup/getStateForOutlet";
import { getCitiesForOutletReducer } from "./dealerGroup/getCitiesForOutlet";
import { getClustersForOutletReducer } from "./dealerGroup/getClusterForOutlet";
import { getDepartmentsReducer } from "./department/getDepartment";
import { departmentReducer } from "./department/addDepartment";
import { inputDrawingReducer } from "./home/layoutTable";
import { designEstimateReducer } from "./home/designEstimate";

import { revisedLayoutViewReducer } from "./home/postRevisedLayoutView";
import { schematicLayoutReducer } from "./home/schematicLayout";
import { gfcReducer } from "./home/gfc";
import { revisedGFCViewReducer } from "./home/postRevisedGFCView";
import { inputDrawingByClusterReducer } from "./home/getInputDrawing";



import { organizationReducer } from "./organization/getOrganization";
import { organizationAddReducer } from "./organization/addOrganization";
import { categoryReducer } from "./category/getCategory";
import { inventoryReducer } from "./inventory";
import { facilityTypeReducer } from "./facilityType";
import { outletTypeReducer } from "./outletType";
import { drawerTypeReducer } from "./drawer";
import { updateOrderStatusReducer } from "./orders/updateOrderStatus";


const rootReducer = combineReducers({
    // auth
    login: loginReducer,
    currentDealor: currentDealorReducer,
    dealorShipLocations: dealorShipLocationsReducer,
    authOtpViaEmail: authOtpViaEmailReducer,
    authOtpViaSMS: authOtpViaSMSReducer,
    verifyAuthOtp: verifyAuthOtpReducer,
    forgetPasswordViaEmail: forgetPasswordViaEmailReducer,
    forgetPasswordViaSMS: forgetPasswordViaSMSReducer,
    resetPasswordVerifyOtp: resetPasswordVerifyOtpReducer,
    resetPassword: resetPasswordReducer,
    updateDealor: updateDealorReducer,
    // orders
    orders: ordersReducer,
    orderDetails: orderDetailsReducer,
    billInvoices: billInvoicesReducer,
    placeOrder: placeOrderReducer,
    dispatchStatusCount: dispatchStatusCountReducer,
    notificationCount: notificationCountReducer,
    ordersCount: ordersCountReducer,
    pendingOrdersCount: pendingOrdersCountReducer,
    uploadInvoice: uploadInvoiceReducer,
    // products
    products: productsReducer,
    prodcutCRUD:prodcutCRUDReducer,
    //dealorOnBoarding
    addApplicantReducer: addApplicantReducer,
    getApplicantSliceReducer:getApplicantSliceReducer,
    getEmailTempleteReducer:getEmailTempleteReducer,
    //KIN
    addKinUserReducer:addKinUserReducer,
    getKinUserSliceReducer:getKinUserSliceReducer,
    //INNOOCEANS
    addInnoceansUserReducer:addInnoceansUserReducer,
    getInnoceansUserSliceReducer:getInnoceansUserSliceReducer,
    //Group dealor
    getGroupDealorSliceReducer:getGroupDealorSliceReducer,
    addDealerGroupReducer:addDealerGroupReducer,
    getCitiesReducer:getCitiesReducer,
    outletTypeReducer:outletTypeReducer,
    facilityTypeReducer:facilityTypeReducer,
    getGroupOutletSliceReducer:getGroupOutletSliceReducer,
    addDealerGroupOutletReducer: addDealerGroupOutletReducer,

    addInviteReducer:addInviteReducer,
    getInviteSliceReducer:getInviteSliceReducer,
    copyEmailTemplateReducer:copyEmailTemplateReducer,
    getEmailTempleteIdReducer:getEmailTempleteIdReducer,
    //SUPPLIER
    getSupplierUserSliceReducer:getSupplierUserSliceReducer,
    addSupplierUserReducer:addSupplierUserReducer,
    bulkOutletUploadReducer:bulkOutletUploadReducer,

    //Roles & AccessManagement
    getUserTypeReducer:getUserTypeReducer,
    addRoleSliceReducer:addRoleSliceReducer,
    getUserRoleReducer : getUserRoleReducer,

    //Department
    getDepartmentsReducer:getDepartmentsReducer,
    departmentReducer:departmentReducer,

    //User Detail
    getUserDetailReducer:getUserDetailReducer,
    editUserDetailReducer:editUserDetailReducer,
    //cluster region
    getRegionForOutletReducer:getRegionForOutletReducer,
    getStateForOutletReducer:getStateForOutletReducer,
    getCitiesForOutletReducer:getCitiesForOutletReducer,
    getClustersForOutletReducer:getClustersForOutletReducer,

    //home/layout
    inputDrawingReducer:inputDrawingReducer,
    designEstimateReducer:designEstimateReducer,

    revisedLayoutViewReducer:revisedLayoutViewReducer,
    schematicLayoutReducer:schematicLayoutReducer,
    gfcReducer:gfcReducer,
    revisedGFCViewReducer:revisedGFCViewReducer,
    inputDrawingByClusterReducer:inputDrawingByClusterReducer,

    organizationReducer: organizationReducer,
    organizationAddReducer:organizationAddReducer,

    categoryReducer:categoryReducer,
    inventoryReducer:inventoryReducer,
    drawerTypeReducer: drawerTypeReducer,
    updateOrderStatusReducer: updateOrderStatusReducer


})

export default rootReducer