import {
    Button,
    InputAdornment,
    Stack,
    Typography,
    TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Body from "../../components/Body";
import Appbar from "../../components/Appbar"
import Drawer from "../../components/Drawer"
import SearchIcon from "@mui/icons-material/Search";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../reusable/DataTable";
import { Switch } from "@mui/material";
import BulkUpload from "../reusable/BulkUpload";
import { getSupplierUserApi, supplierUserActive } from "../../store/supplierUser/getSupplierUser";
import { deleteSupplierUserApi } from "../../store/supplierUser/addSupplierUser";
import { showToast } from "../../toast/toast";
import DeleteConfirmationModal from "../reusable/DeleteConfirmationModal";
import ClearIcon from '@mui/icons-material/Clear';
import GroupIcon from '@mui/icons-material/Group';


export default function KinUsers() {
    const [bulkUploadModal, setBulkUploadModal] = useState(false);
    const [rowsData, setRowsData] = useState();
    const [deleteId, setDeleteId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    localStorage.removeItem("selectedId");

    const getSupplierUser = useSelector((state) => state?.getSupplierUserSliceReducer?.result?.data);
    const [supplierUsertData, setSupplierUsertData] = useState(getSupplierUser ? getSupplierUser : []);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectedRows = (data) => {
        setSelectedRows(data);
    };


    const columns = [
        { field: "user_first_name", headerName: "First Name", flex: 1 },
        { field: "user_last_name", headerName: "Last Name", flex: 1 },
        { field: "user_email", headerName: "Email", flex: 1 },
        { field: "user_phone", headerName: "Phone Number", flex: 1 },
        { field: "city", headerName: "Location", flex: 1 },
        { field: "zone", headerName: "Zone", flex: 1 },
        {
            field: "user_is_active",
            headerName: "Active/Inactive",
            //sortable: false,
            width: 150,
            renderCell: (params) => {
                const isActive = params && params?.row?.user_is_active;
                return (
                    <Switch
                        checked={isActive || false}
                        onChange={() => handleChange(params?.row?.id, !isActive)}
                        color="primary"
                    />
                );
            },
        },
    ];

    const handleBulkUpload = () => {
        inputRef.current.click();
    };

    const handleNavigate = () => {
        navigate("/supplier/add-supplieruser");
    };

    const editUser = () => {
        navigate("/supplier/edit-supplieruser");
    };

    const deleteUser = async (id) => {
        setOpenModal(true);
        setDeleteId(id)
    };
    
    const handleCloseModal = () => {
          setOpenModal(false);
    };
    
    const handleConfirmDelete = async() => {
        const deleteData = await dispatch(deleteSupplierUserApi(deleteId));
        if(deleteData?.error){
            const { message } = deleteData?.payload;
        }else{
          showToast(deleteData?.payload?.message, 1)
          dispatch(getSupplierUserApi());
          setDeleteId(null)
          handleCloseModal()
        }
    };

    const handleChange = async (id, activeState) => {
        const formData = {
            "user_id": id,
            "user_status": activeState
        }
        
        const response = await dispatch(supplierUserActive(formData));
          if(response?.payload?.status){
            dispatch(getSupplierUserApi());
          }
    };

    useEffect(() => {
        dispatch(getSupplierUserApi());
    }, [dispatch]);

    useEffect(() => {
        setSupplierUsertData(getSupplierUser);
    }, [getSupplierUser]);

    useEffect(() => {
        // if (supplierUsertData?.length > 0) {
            const newData = (supplierUsertData || []).map((supplierUser, index) => ({
                id: supplierUser?.user_id,
                user_first_name: supplierUser?.user_first_name,
                user_last_name: supplierUser?.user_last_name,
                user_email: supplierUser?.user_email,
                user_phone: supplierUser?.user_phone,
                city: supplierUser?.city_name,
                zone: supplierUser?.zone_name,
                user_is_active: supplierUser?.user_is_active,
                //department: kinUser.department,
            }));
            setRowsData(newData);
        // }
    }, [supplierUsertData]);


    const [searchText, setSearchText] = useState();
    const handleChangeSearch = (e) => {
        e.preventDefault();
        const searchTerm = e.target.value.toLowerCase();
        setSearchText(searchTerm);
    };

    const searchData = (searchText) => {
        if (!searchText) {
            setSupplierUsertData(getSupplierUser);
            return;
        }
        const filteredData = getSupplierUser.filter((row) => {
            const firstName = (row.user_first_name || "").toLowerCase();
            const lastName = (row.user_last_name || "").toLowerCase();
            const city = (row.city || "").toLowerCase();
            const email = (row.user_email || "").toLowerCase();
            const zone = (row.zone || "").toLowerCase();
            const phone = String(row.user_phone);

            return (
                firstName.includes(searchText) ||
                lastName.includes(searchText) ||
                city.includes(searchText) ||
                email.includes(searchText) ||
                zone.includes(searchText) ||
                phone.includes(searchText)
            );
        });

        setSupplierUsertData(filteredData);
    }
    useEffect(() => {
    searchData(searchText)
    },[searchText])
    
    return (
        <>
            
            <Body>
                <Grid container alignItems="center" flexWrap={"wrap"} spacing={1}>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ my: 2 }}
                            alignItems="center"
                        >
                            <GroupIcon/>
                            <Typography variant="h6">Suppliers</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={9}>
                        <Grid
                            container
                            flexWrap={"nowrap"}
                            spacing={1}
                            justifyContent={"end"}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                sx={{
                                    marginRight: {
                                        md: 0.5,
                                        sm: 0,
                                    },
                                }}
                            >
                                <TextField
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end" onClick={() => setSearchText("")}>
                                                { searchText?.length > 0 ? <ClearIcon style={{cursor:"pointer"}} /> : ""}
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search by Name, Phone, Email"
                                    value={searchText}
                                    onChange={handleChangeSearch}

                                />
                            </Grid>
                            <Grid item sm="auto">
                                <Button variant="contained" onClick={handleNavigate}>
                                    Add Supplier
                                </Button>
                            </Grid>
                            {/* <Grid item sm="auto">
                                {" "}
                                <input
                                    type="file"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => setBulkUploadModal(true)}
                                >
                                    BULK UPLOAD
                                </Button>{" "}
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sx={{ overflowX: "auto", width: "100%" }}>
                        <DataTable
                            data={rowsData ? rowsData : []}
                            columns={columns}
                            handleSelectedRows={handleSelectedRows}
                            deleteUser={deleteUser}
                            editUser={editUser}
                            type="supplier"
                        />
                    </Grid>
                </Grid>
            </Body>
            <BulkUpload open={bulkUploadModal} setOpen={setBulkUploadModal} />
            <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}  handleConfirm={handleConfirmDelete}  deleteType='Supplier User'/>


        </>
    )
}