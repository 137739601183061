import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const verifyAuthOtpApi = createAsyncThunk(
    'auth/verify-auth-otp',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/login/verify_otp/`, 
                data,
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const resetVerifyAuthOtp = createAsyncThunk(
    'auth/reset-verify-auth',
    async (_, { rejectWithValue }) => {
        try {
            // Implement your logout logic here (if needed)
            // For example, clearing tokens, etc.
            // This is an empty example as it may vary based on your application
            // Assuming logout doesn't need any API call and just resetting state
            return { message: "Logout successful" };
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


const verifyAuthOtpSlice = createSlice({
    name: 'verify-auth-otp',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(verifyAuthOtpApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(verifyAuthOtpApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(verifyAuthOtpApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })

        builder.addCase(resetVerifyAuthOtp.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(resetVerifyAuthOtp.fulfilled, (state, action) => {
            state.loading = false
            state.result = []
            state.message = action?.payload?.message
        })  
        builder.addCase(resetVerifyAuthOtp.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const verifyAuthOtpReducer = verifyAuthOtpSlice.reducer