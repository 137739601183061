import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, Typography } from '@mui/material';
import LogoWhite from "../assets/img/kialogowhite.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from 'react-router-dom';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { menuItems } from './MenuItems';

const MobileDrawer = ({isHamburgerOpen, Style, closeHamburger, expandedAccordion, handleAccordionChange, navigate, handleLogout, classes}) => {
    const { pathname } = useLocation();
    return (
        <Box className={isHamburgerOpen ? Style?.open : Style?.close}>
            <Box className={Style?.logo} onClick={() => navigate('/')}>
                <img src={LogoWhite} alt="logo" onClick={closeHamburger} />
                <HighlightOffOutlinedIcon
                    className={Style.closemobile}
                    sx={{ height: '30px', width: '30px', color: '#ffffff' }}
                    onClick={closeHamburger}
                />
            </Box>
            <List sx={{ width: '100%', fontSize: '15px', fontWeight: '400' }}>
                {menuItems.map((item, index) => {
                const isActive = pathname.includes(item.key);
                const hasSubItems = item.subItems && item.subItems.length > 0;
                return (
                    <ListItem
                    sx={{ p: 0 }}
                    key={index}
                    className={isActive ? Style.activeLink : Style?.inActiveLink}
                    >
                    {hasSubItems ? (
                        <Accordion
                        expanded={expandedAccordion === item.key}
                        onChange={handleAccordionChange(item.key)}
                        sx={{ flexGrow: '1', width: '100%' }}
                        >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                            pl: 2,
                            pr: 2,
                            borderBottom: '1px solid rgba(42, 58, 70, 1)'
                            }}
                            aria-controls={item.key + '-content'}
                            id={item.key + '-header'}
                        >
                            {item.icon}
                            <Typography sx={{ ml: 1, fontSize: '14px' }}>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                            width: '100%',
                            boxSizing: 'border-box',
                            backgroundColor: 'rgba(8, 27, 41, 1)',
                            p: 0,
                            px: 4
                            }}
                        >
                            <ul className={classes.menuUnstyledList}>
                            {item.subItems.map((subItem, subIndex) => (
                                <li key={subIndex} onClick={closeHamburger} className={
                                        subItem.path == pathname  ? "active" : ""
                                    } >
                                <Link to={subItem.path}>
                                    {subItem.title}
                                </Link>
                                </li>
                            ))}
                            </ul>
                        </AccordionDetails>
                        </Accordion>
                    ) : item.onclick === 'logout' ? (
                        <Accordion
                        expanded={expandedAccordion === item.key}
                        onChange={handleAccordionChange(item.key)}
                        sx={{ flexGrow: '1', width: '100%' }}
                        onClick={handleLogout}
                        >
                        <AccordionSummary
                            sx={{
                            pl: 2,
                            pr: 2,
                            borderBottom: '1px solid rgba(42, 58, 70, 1)'
                            }}
                            aria-controls={item.key + '-content'}
                            id={item.key + '-header'}
                        >
                            {item.icon}
                            <Typography sx={{ ml: 1, fontSize: '14px' }}>{item.title}</Typography>
                        </AccordionSummary>
                        </Accordion>
                    ) : (
                        <Accordion
                            expanded={expandedAccordion === item.key}
                            onChange={handleAccordionChange(item.key)}
                            sx={{ flexGrow: '1', width: '100%' }}
                            onClick={() => {
                                closeHamburger();
                                navigate(item.path);
                            }}
                        >
                        <AccordionSummary
                            sx={{
                            pl: 2,
                            pr: 2,
                            borderBottom: '1px solid rgba(42, 58, 70, 1)'
                            }}
                            aria-controls={item.key + '-content'}
                            id={item.key + '-header'}
                        >
                            {item.icon}
                            <Typography sx={{ ml: 1, fontSize: '14px' }}>{item.title}</Typography>
                        </AccordionSummary>
                        </Accordion>
                    )}
                    </ListItem>
                );
                })}
            </List>
        </Box>
    )
}

export default MobileDrawer