import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios';

export const getDepartment = createAsyncThunk(
    'department/get-department',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/kin_user/department/`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const resetDepartmentState = createAction('department/reset-department')

const getDepartments = createSlice({
    name: 'get-department',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDepartment.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
            state.error = null;
        });
        builder.addCase(getDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.result = [];
        });

        builder.addCase(resetDepartmentState, (state, action) => {
            state.result = [];
            state.loading = false;
            state.error = null;
        });

    }
});

export const getDepartmentsReducer = getDepartments.reducer;
