import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Card, IconButton, Link, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyAuthOtpApi } from '../../store/auth/verifyAuthOtp';
import ModalCss from "../../styles/modal.module.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function OTPSMSAuth({ open, setOpen , openPreviousModal}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const loginState = useSelector(state => state.login);
  const handleClose = () => setOpen(false)
  const handleVerify = () => {
    dispatch(verifyAuthOtpApi())
    navigate("/")
  }

  const handleBack = () => {
    handleClose()
    openPreviousModal(true)
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
          <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center"
              position={"absolute"}
              left={5} // Aligns the back button to the left
              right={5} // Aligns the close button to the right
              top={5}
              width="100%"
            >
              {/* Back Arrow Button */}
              <IconButton>
                <ArrowBackIosIcon onClick={handleBack}/>
              </IconButton>

              {/* Close Button */}
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
          </Stack>
            <Stack alignItems='center'>
              <Typography className={ModalCss?.title} variant='h5' fontWeight={600} >Verify OTP</Typography>
              <Typography className={ModalCss?.subtitle} >We have sent an OTP to your registered mobile number</Typography>
              <Typography className={ModalCss?.subtitle} >+91 {loginState.result.phone}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: "50px 30px" }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px"  }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                  textTransform: "none",
                }} variant='contained' 
                onClick={handleVerify}
                disabled={otp?.length < 4}
                >Verify Number</Button>
            </Box>
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={2} fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Didn't receive the OTP?</Typography>
              {/* <Button onClick={() => handleResendOtp()}>Resend</Button> */}
              <Link  onClick={handleClose} sx={{ textTransform: "none" }}>Resend</Link>
            </Stack>
            {/* <Stack justifyContent='center' direction='row' alignItems='center' fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Didn’t receive code?</Typography>
              <Button onClick={handleClose} sx={{fontSize: "18px", fontWeight: "600"}}>Resend</Button>
            </Stack> */}
          </Stack>
        </Card>
      </Modal>
    </>
  )
}
