import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Tooltip, TextareaAutosize } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import pdf from '../../assets/img/pdf.png';
import cad from '../../assets/img/cad.png';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { convertToFormData } from "../ConvertToFormData";
import { gfcLayout } from "../../store/home/gfc";
import { handleDownload } from "../reusable/downloadFile";
import { approveGfcByAdmin } from "../../store/home/approveGfcByAdmin";
import { showToast } from "../../toast/toast";
import RequestForRevision from "../reusable/RequestForRevision";
import DoneIcon from '@mui/icons-material/Done';
export default function Gfc() {
    const dispatch = useDispatch();
    const [isSentFeedBack, setIsSentFeedBack] = useState(false);
    const [approved, setApproved] = useState(false);
    localStorage.removeItem("isRevisedViewClick")
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);  
    const [open, setOpen] = useState(false);
    const {result : GFCData, loading : GFCLoading} = useSelector((state) => state?.gfcReducer);
    const {result : revisedGFCData} = useSelector((state) => state?.revisedGFCViewReducer);
    const [data, setData] = useState();     
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.data?.id
        }
        const formData = convertToFormData(formD);
        try {
            const response = await dispatch(gfcLayout(formData));
            //setData(response?.payload?.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if(localStorage.getItem("isRevisedGFCViewClick")===null){
            getGfcLayout();
        }
           
    }, [localStorage.getItem("isRevisedGFCViewClick")]);

    const [gfcPdfExists, setGfcPdfExists] = useState(false);
    const [gfcCadExists, setGfcCadExists] = useState(false);
  
    const handleApproveGfcByAdmin = async () => {
        const formData = convertToFormData({ 'gfc_id': data.id });
        try {
            const response = await dispatch(approveGfcByAdmin(formData));
            showToast(response?.payload?.message, 1);
            setApproved(true);
            setIsSentFeedBack(true);
            getGfcLayout();
        } catch (error) {
            console.error("Error in sending data to Kin:", error);
        }
    };

    useEffect(() => {
        if(data?.revision_request===true || data?.approve_by_admin===true){
          setIsSentFeedBack(true);
        }
    },[data])

    useEffect(() => {
        if(data?.approve_by_admin===true){
            setApproved(true);
        }
    },[data])

    useEffect(() => {
        setGfcPdfExists(Array.isArray(data?.gfc_pdf) && data.gfc_pdf.length > 0);
        setGfcCadExists(Array.isArray(data?.gfc_cad) && data.gfc_cad.length > 0);
    }, [data?.gfc_pdf, data?.gfc_cad]);

   
    useEffect(() => {
        if (localStorage.getItem("isRevisedGFCViewClick")===null) {
            getGfcLayout();
        }
    }, [localStorage.getItem("isRevisedGFCViewClick")]);
    
    useEffect(() => {
        if (localStorage.getItem("isRevisedGFCViewClick")==='1') {
            setData(revisedGFCData);
        }else{
            setData(GFCData);
        }
    }, [revisedGFCData, GFCData, localStorage.getItem("isRevisedGFCViewClick")])

    useEffect(() => {
        const isRevisedGFCViewClick = localStorage.getItem("isRevisedGFCViewClick");
        setData(isRevisedGFCViewClick === '1' ? revisedGFCData : GFCData);
      }, [revisedGFCData, GFCData]);


    return (
        <>
        {gfcPdfExists ? (
        <Box className="h-100 d-flex flex-column justify-content-between">
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                <Typography variant="h6" className="fs-17 fw-400">GFC Received</Typography>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" flexDirection={"column"}>
                <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                    {gfcPdfExists && (
                        <Box sx={{ width: "213px" }}>
                        {data?.gfc_pdf?.map((data) => (

                            <Box className="text-center p-relative">
                                <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                    <Typography>
                                        {'PDF File'}
                                    </Typography>
                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', data.files)} />
                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data.files}`)} />
                                </Tooltip>
                            </Box>
                        ))}
                        </Box>
                        
                    )}

                    {gfcCadExists && (
                        <Box sx={{ width: "213px" }}>
                        {data?.gfc_cad?.map((data) => (
                                <>
                                    <Box className="text-center p-relative">
                                        <Tooltip title={data.files} placement="top" className={` uploadedfilename`} >
                                            <img width={40} height={40} src={cad} className="my-auto" />
                                            <Typography>
                                                {'CAD File'}
                                            </Typography>
                                            <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', data.files)} />
                                            <FullscreenIcon className="my-auto" onClick={() => window.open(`https://kiastageapi.dev.vinove.com${data.files}`)} />
                                        </Tooltip>
                                    </Box>
                                </>
                            ))}
                        </Box>
                    )}
                    
                </Box>
                <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment</Typography>
                <TextareaAutosize disabled style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }} className="bg-white" minRows={6} placeholder={data?.comment} />

                <Grid container spacing={2} marginTop={"15px"}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                    <Button
                        className="black-btn" 
                    
                        onClick={() => handleApproveGfcByAdmin()}
                        disabled={approved || isSentFeedBack}
                    >
                        {approved ? "Approved" : "Approve"}
                        {approved && <DoneIcon sx={{ marginLeft: "5px" }} />}
                    </Button>
                    {/* {data?.approve_by_admin ?
                        <Button className="black-btn" style={{ color: "green" }} disabled>
                            <DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }} /> Approved
                        </Button>
                        :
                        <Button className="black-btn" onClick={handleApproveGfcByAdmin}>Approve</Button>
                    } */}
                    <Button disabled={isSentFeedBack} className="white-btn" onClick={handleOpen}>Request for revision</Button>
                </Grid>
                </Grid>
            </Grid>
           
           
        </Grid>
        </Box>
    ) : (
            <div className="d-flex h-100"><p className="m-auto">No data found </p></div>
            )}
        <RequestForRevision open={open} handleClose={handleClose} getData={getGfcLayout}/>
 


</>
        
    );
}
