import React, { useRef, useState, useEffect } from "react";
import {
    Button,
    FormControl,
    Grid,
    Typography,
    Stack,
    FormHelperText,
    TextField,
    CircularProgress,
    Box,
} from "@mui/material";
import Body from "../../../components/Body";
import FormStyle from "../../../styles/field.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addDepartmentApi, editDepartmentApi } from "../../../store/department/addDepartment";
import { getDepartment } from "../../../store/department/getDepartment";
import { AddDepartmentSchema } from "../../validationSchemas";
import { showToast } from "../../../toast/toast";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export default function AddDepartment() {
    // const inputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [addRoleRequest, setAddRoleRequest] = useState()
    const getAllDepartment = useSelector((state) => state?.getDepartmentsReducer?.result?.data);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setAddRoleRequest((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
        updateError(name)
    }

    const updateError = (name) => {
        setErrors((preValue) => {
            return {
                ...preValue,
                [name]: ""
            }
        })
    }

    const handleCancel = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (localStorage.getItem("selectedId") && getAllDepartment) {
            const EditData = localStorage.getItem("selectedId") ? getAllDepartment?.filter((row, index) => row.id == localStorage.getItem('selectedId')) : []
            setAddRoleRequest({
                'department_name': EditData?.[0]?.name,
            })
        }
    }, [localStorage.getItem("selectedId"), getAllDepartment]);

    const addRequestAPI = async () => {
        setLoading(true);
        const dataToBeSent = {
            department_name: addRoleRequest?.department_name.trim(),
        };
        const addDepartmentResponse = await dispatch(addDepartmentApi(dataToBeSent));
        if(addDepartmentResponse?.payload?.status === "error"){
            showToast(addDepartmentResponse?.payload?.message, 2);
            return
        }
        showToast(addDepartmentResponse?.payload?.message, 1)
        setLoading(false);
        navigate("/master/department");
    }

    const editRequestAPI = async () => {
        setLoading(true);
        const dataToBeSent = {
            department_id: localStorage.getItem("selectedId"),
            department_name: addRoleRequest?.department_name.trim(),
        };

        const editDepartmentResponse = await dispatch(editDepartmentApi(dataToBeSent));
        if(editDepartmentResponse?.payload?.status === "error"){
            showToast(editDepartmentResponse?.payload?.message, 2);
            return
        }
        showToast(editDepartmentResponse?.payload?.message, 1)
        dispatch(getDepartment());
        setLoading(false);
        navigate("/master/department");

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        AddDepartmentSchema.validate(addRoleRequest, { abortEarly: false })
            .then(() => {
                if (localStorage?.getItem("selectedId") && localStorage.getItem("selectedId") !== undefined && localStorage.getItem("selectedId") !== "") {
                    editRequestAPI()
                } else {
                    addRequestAPI();
                }
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
    };
    return (
        <>
            <Body>
                {/* <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                    {'<'}
                </span> */}
                <KeyboardArrowLeftIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />

                <form onSubmit={handleSubmit}>
                <Grid container marginBlockStart={2} spacing={2} sx={{ marginBlockEnd: "40px" }}>
                        <Grid item md={12} xs={12}>
                            <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                Department Name
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                           
                            <FormControl fullWidth>
                            <TextField
                                id="input"
                                variant="outlined"
                                value={addRoleRequest?.department_name ? addRoleRequest?.department_name : ""}
                                name="department_name"
                                onChange={handleChange}
                                fullWidth
                                error={Boolean(errors.department_name)}
                                helperText={errors.department_name}
                                inputProps={{ maxLength: 50 }}
                            />
                            </FormControl>
                                   
                        </Grid>

                        <Grid item md={6} xs={12} >
                            <Box sx={{ display: "flex",gap:"10px"}}>

                                <Button
                                className="black-btn"
                                        disabled={loading}
                                        type="submit"
                                        
                                        sx={{ padding: "5px 70px !important", fontSize: "17px",height:"54.56px" ,borderRadius: "8px", backgroundColor: "black" }}
                                    >
                                        {loading ? <CircularProgress sx={{ color: "#ffffff" }} /> : localStorage.getItem("selectedId") ? "Update" : "Create"}
                                </Button>

                                <Button
                                        variant="outlined"
                                        size="large"
                                        onClick={() => navigate(-1)}
                                        sx={{ padding: "5px 30px !important", fontSize: "17px",height:"54.56px" ,borderRadius: "8px", }}
                                    >
                                        Cancel
                                </Button>
                            </Box>
                            
                        </Grid>
            
                    
                </Grid>
            
             
                    
                </form>
            </Body>
        </>
    );
}
