import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgetPassword from "../view/auth/ForgetPassword";
import Login from "../view/auth/Login";
import Home from "../view/home";
import BillsAndInvoices from "../view/orders/BillsAndInvoices";
import DispatchStatus from "../view/orders/DispatchStatus";
import PurchaseOrders from "../view/orders/PurchaseOrders";
import YourOrder from "../view/orders/YourOrder";
import DealorProfile from "../view/profile/DealorProfile";
import ProtectedRoute from "./ProtectedRoute";
// import AddApplicant from "../view/profile/AddApplicant";
import OnBoarding from "../view/dealers/OnBoarding";
import AddApplicant from "../view/dealers/AddApplicant";
import DealerUsers from "../view/dealers/Users";
import DealerOutlet from "../view/dealers/DealorOutlet";
import AddDealerOutlet from "../view/dealers/AddDealorOutlet";
import DealerUsersAddGroup from "../view/dealers/AddGroup";
import AddKinUser from "../view/kin/AddKinUser";
import KinUsers from "../view/kin/KinUsers";
import PrevEdit from "../view/home/PrevEdit";
import InnoceansUsers from "../view/innoceans/InnoceansUser";
import AddInnoceansUser from "../view/innoceans/AddInnoceansUser";
import SupplierUser from "../view/supplier/SupplierUser"
import AddSupplierUser from "../view/supplier/AddSupplierUser"
import SavedInvite from "../view/dealers/SavedInvites";
import Roles from "../view/roles/Roles";
import AddRoles from "../view/roles/AddRoles";
import MyProfile from "../view/adminProfile/UserProfile";
import ProtectedLayout from "../components/ProtectedLayout";
import Department from "../view/master/Department";
import AddDepartment from "../view/master/Department/AddDepartment";
import Organization from "../view/master/Organization";
import AddOrganization from "../view/master/Organization/AddOrganization";
import Layout from "../view/home/Layout";
import LayoutTable from "../view/home/LayoutTable";
import AddMaterial from "../view/master/MaterialCategory/AddMaterial";
import Material from "../view/master/MaterialCategory/Material";
import Inventory from "../view/master/Inventory";
import Order from "../view/OrderManagement";
import View from "../view/OrderManagement/view";
import OrderStatus from "../view/OrderManagement/OrderStatus";

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedLayout />}>
          <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/user/profile" element={<ProtectedRoute><MyProfile /></ProtectedRoute>} />
          <Route path="/dealer/onboarding" element={<ProtectedRoute><OnBoarding /></ProtectedRoute>} />
          <Route path="/dealer/add-applicant" element={<ProtectedRoute><AddApplicant /></ProtectedRoute>} />
          <Route path="/master/department" element={<ProtectedRoute><Department /></ProtectedRoute>} />
          <Route path="/department/add" element={<ProtectedRoute><AddDepartment /></ProtectedRoute>} />
          <Route path="/master/organization" element={<ProtectedRoute><Organization /></ProtectedRoute>} />
          <Route path="/organization/add" element={<ProtectedRoute><AddOrganization /></ProtectedRoute>} />
          <Route path="/master/material" element={<ProtectedRoute><Material /></ProtectedRoute>} />
          <Route path="/master/material-category" element={<ProtectedRoute><AddMaterial /></ProtectedRoute>} />
          <Route path="/master/inventory" element={<ProtectedRoute><Inventory /></ProtectedRoute>} />
          <Route path="/orders" element={<ProtectedRoute><Order /></ProtectedRoute>} />
          <Route path="/order/view" element={<ProtectedRoute><View /></ProtectedRoute>} />
          <Route path="/orders/purchase" element={<ProtectedRoute><PurchaseOrders /></ProtectedRoute>} />
          <Route path="/orders/status" element={<ProtectedRoute><OrderStatus /></ProtectedRoute>} />
          <Route path="/orders/bills-invoices" element={<ProtectedRoute><BillsAndInvoices /></ProtectedRoute>} />
          <Route path="/orders/dispatch-status" element={<ProtectedRoute><DispatchStatus /></ProtectedRoute>} />
          <Route path="/orders/created" element={<ProtectedRoute><YourOrder /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><DealorProfile /></ProtectedRoute>} />
          <Route path="/users/dealer" element={<ProtectedRoute><DealerUsers /></ProtectedRoute>} />
          <Route path="/dealer/editInvite" element={<ProtectedRoute><DealerUsers /></ProtectedRoute>} />
          <Route path="/users/dealer-outlet" element={<ProtectedRoute><DealerOutlet /></ProtectedRoute>} />
          <Route path="/users/add-dealer-outlet" element={<ProtectedRoute><AddDealerOutlet /></ProtectedRoute>} />
          <Route path="/dealer/savedInvite" element={<ProtectedRoute><SavedInvite /></ProtectedRoute>} />
          <Route path="/users/dealer/add-group" element={<ProtectedRoute><DealerUsersAddGroup /></ProtectedRoute>} />
          <Route path="/users/dealer/edit-group" element={<ProtectedRoute><DealerUsersAddGroup /></ProtectedRoute>} />
          <Route path="/users/kin" element={<ProtectedRoute><KinUsers /></ProtectedRoute>} />
          <Route path="/users/innoceans" element={<ProtectedRoute><InnoceansUsers /></ProtectedRoute>} />
          <Route path="/users/suppliers" element={<ProtectedRoute><SupplierUser /></ProtectedRoute>} />
          <Route path="/roles" element={<ProtectedRoute><Roles /></ProtectedRoute>} />
          <Route path="/roles/add" element={<ProtectedRoute><AddRoles /></ProtectedRoute>} />
          <Route path="/kin/add-kinuser" element={<ProtectedRoute><AddKinUser /></ProtectedRoute>} />
          <Route path="/kin/edit-kinuser" element={<ProtectedRoute><AddKinUser /></ProtectedRoute>} />
          <Route path="/innoceans/add-innoceanuser" element={<ProtectedRoute><AddInnoceansUser /></ProtectedRoute>} />
          <Route path="/supplier/add-supplieruser" element={<ProtectedRoute><AddSupplierUser /></ProtectedRoute>} />
          <Route path="/supplier/edit-supplieruser" element={<ProtectedRoute><AddSupplierUser /></ProtectedRoute>} />
          <Route path="/layout/view" element={<ProtectedRoute><Layout /></ProtectedRoute>} />
          <Route path="/layout" element={<ProtectedRoute><LayoutTable /></ProtectedRoute>} />
          <Route path="/prev-edit/:id" element={<ProtectedRoute><PrevEdit /></ProtectedRoute>} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
      </Routes>
    </BrowserRouter>
    // <BrowserRouter>
    //   <Routes>
    //   <Route path="/" element={<ProtectedLayout />}>
    //     <Route
    //       path="/"
    //       element={
    //         <ProtectedRoute>
    //           <Home />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/user/profile"
    //       element={
    //         <ProtectedRoute>
    //           <MyProfile />
    //         </ProtectedRoute>
    //       }
    //     />

    //     <Route
    //       path="/dealer/onboarding"
    //       element={
    //         <ProtectedRoute>
    //           <OnBoarding />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/dealer/add-applicant"
    //       element={
    //         <ProtectedRoute>
    //           <AddApplicant />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/master/department"
    //       element={
    //         <ProtectedRoute>
    //           <Department />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/master/organization"
    //       element={
    //         <ProtectedRoute>
    //           <Organization/>
    //         </ProtectedRoute>
    //       }
    //     />
    //      <Route
    //       path="/organization/add"
    //       element={
    //         <ProtectedRoute>
    //           <AddOrganization/>
    //         </ProtectedRoute>
    //       }
    //     />
        
    //     <Route
    //       path="/master/material"
    //       element={
    //         <ProtectedRoute>
    //           <Material/>
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/master/material-category"
    //       element={
    //         <ProtectedRoute>
    //           <AddMaterial/>
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/master/inventory"
    //       element={
    //         <ProtectedRoute>
    //           <Inventory/>
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/kin/add-kinuser"
    //       element={
    //         <ProtectedRoute>
    //           <AddKinUser />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/kin/edit-kinuser"
    //       element={
    //         <ProtectedRoute>
    //           <AddKinUser />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/innoceans/add-innoceanuser"
    //       element={
    //         <ProtectedRoute>
    //           <AddInnoceansUser />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/supplier/add-supplieruser"
    //       element={
    //         <ProtectedRoute>
    //           <AddSupplierUser />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/supplier/edit-supplieruser"
    //       element={
    //         <ProtectedRoute>
    //           <AddSupplierUser />
    //         </ProtectedRoute>
    //       }
    //     />
    //       <Route
    //       path="/layout/view"
    //       element={
    //         <ProtectedRoute>
    //           <Layout/>
    //         </ProtectedRoute>
    //       }
    //     />
    //      <Route
    //       path="/layout"
    //       element={
    //         <ProtectedRoute>
    //           <LayoutTable/>
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/orders"
    //       element={
    //         <ProtectedRoute>
    //           <Order />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/order/view"
    //       element={
    //         <ProtectedRoute>
    //           <View/>
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/orders/purchase"
    //       element={
    //         <ProtectedRoute>
    //           <PurchaseOrders />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/orders/status"
    //       element={
    //         <ProtectedRoute>
    //           <OrderStatus />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/orders/bills-invoices"
    //       element={
    //         <ProtectedRoute>
    //           <BillsAndInvoices />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/orders/dispatch-status"
    //       element={
    //         <ProtectedRoute>
    //           <DispatchStatus />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/orders/created"
    //       element={
    //         <ProtectedRoute>
    //           <YourOrder />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/profile"
    //       element={
    //         <ProtectedRoute>
    //           <DealorProfile />
    //         </ProtectedRoute>
    //       }
    //     />

    //     <Route
    //       path="/users/dealer"
    //       element={
    //         <ProtectedRoute>
    //           <DealerUsers />
    //         </ProtectedRoute>
    //       }
    //     />
    //      <Route
    //       path="/dealer/editInvite"
    //       element={
    //         <ProtectedRoute>
    //           <DealerUsers />
    //         </ProtectedRoute>
    //       }
    //     />

    //     <Route
    //       path="/users/dealer-outlet"
    //       element={
    //         <ProtectedRoute>
    //           <DealerOutlet />
    //         </ProtectedRoute>
    //       }
    //     />

    //     <Route
    //       path="/users/add-dealer-outlet"
    //       element={
    //         <ProtectedRoute>
    //           <AddDealerOutlet />
    //         </ProtectedRoute>
    //       }
    //     />

    //     <Route
    //       path="/dealer/savedInvite"
    //       element={
    //         <ProtectedRoute>
    //           <SavedInvite />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/users/dealer/add-group"
    //       element={
    //         <ProtectedRoute>
    //           <DealerUsersAddGroup />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/users/dealer/edit-group"
    //       element={
    //         <ProtectedRoute>
    //           <DealerUsersAddGroup />
    //         </ProtectedRoute>
    //       }
    //     />


    //     <Route
    //       path="/users/kin"
    //       element={
    //         <ProtectedRoute>
    //           <KinUsers />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/users/innoceans"
    //       element={
    //         <ProtectedRoute>
    //           <InnoceansUsers />
    //         </ProtectedRoute>
    //       }
    //     />
    //     <Route
    //       path="/users/suppliers"
    //       element={
    //         <ProtectedRoute>
    //           <SupplierUser />
    //         </ProtectedRoute>
    //       }
    //     />
    //      <Route
    //       path="/roles/"
    //       element={
    //         <ProtectedRoute>
    //           <Roles />
    //         </ProtectedRoute>
    //       }
    //     />
    //       <Route path="/prev-edit/:id" element={ <ProtectedRoute>
    //       <PrevEdit />
    //         </ProtectedRoute>} />
    //     <Route
    //       path="/roles/add"
    //       element={
    //         <ProtectedRoute>
    //           <AddRoles />
    //         </ProtectedRoute>
    //       }
    //     />
    //       <Route
    //       path="/department/add"
    //       element={
    //         <ProtectedRoute>
    //           <AddDepartment/>
    //         </ProtectedRoute>
    //       }
    //     />

    //     </Route>
    //     <Route path="/login" element={<Login />} />
    //     <Route path="/forget-password" element={<ForgetPassword />} />
      
    //   </Routes>
    // </BrowserRouter>
  );
}
