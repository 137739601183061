import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyAuthOtpApi } from "../../store/auth/verifyAuthOtp";
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail";
import BackdropUI from "../../ui/Backdrop";
import DealerStatusConf from "./DealerStatusConf";
import { currentDealorApi } from "../../store/auth/currentDealor";
import ModalCss from "../../styles/modal.module.scss";
import { showToast } from "../../toast/toast";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import OTPLoginAuth from "./OTPLoginAuth";
export default function OTPEmailAuth({
  open,
  setOpen,
  handleSendLink,
  openPreviousModal,
  handleCloseP,
}) {
  const authOtpViaEmailSelector = useSelector(
    (state) => state?.authOtpViaEmail
  );
  const { loading } = authOtpViaEmailSelector;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [backdrop, setBackDrop] = useState(false);
  const [dealorStatus, setDealorStatus] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [OTPAuthModel, setOTPAuthModel] = useState(false);
  const user_type = "SuperAdmin";
  const dealorEmail = loginState?.result?.email
    ? loginState?.result?.email
    : localStorage.getItem("email");
  const handleClose = () => {
    handleCloseP();
    setOpen(false);
    setOtp("");
  };
  const [otpError, setOtpError] = useState("");
  const handleVerify = async () => {
    setOtpError("");
    const verifyAuthOTP = await dispatch(
      verifyAuthOtpApi({
        otp: Number(otp),
        user_type,
        type: "email",
        email: loginState?.result?.email,
      })
    );

    if (!verifyAuthOTP?.payload?.error) {
      localStorage.setItem(
        "access_token",
        verifyAuthOTP?.payload?.result?.access_token
      );

      navigate("/");
    } else {
      //setOtpError(verifyAuthOTP?.payload?.message)
      showToast(verifyAuthOTP?.payload?.message, 2);
    }
  };

  const handleResendOtp = () => {
    setOtp("");
    handleSendLink();
    showToast("OTP sent successfully", 1);
  };

  let maskedEmail = "";
  if (dealorEmail) {
    const [localPart, domain] = dealorEmail.split("@");
    maskedEmail = `${localPart.slice(0, 3)}*****@${domain}`;
  }

  const handleBack = () => {
    handleClose();
    openPreviousModal(true);
  };

  useEffect(() => {
    if (loading) setBackDrop(true);
    else setBackDrop(false);
  }, [backdrop, loading]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              position={"absolute"}
              left={5}
              right={5}
              top={5}
              width="100%"
            >
              <IconButton>
                <ArrowBackIosIcon onClick={handleBack} />
              </IconButton>

              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>
            <Stack alignItems="center">
              <Typography
                className={ModalCss?.title}
                variant="h5"
                fontWeight={600}
              >
                Verify OTP
              </Typography>
              <Typography
                className={ModalCss?.subtitle}
              >{`We have sent an OTP to your registered email address `}</Typography>
              <Typography className={ModalCss?.subtitle}>
                {" "}
                {maskedEmail}
              </Typography>
            </Stack>
            <OtpInput
              containerStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBlock: "50px 30px",
              }}
              inputStyle={{
                width: 72,
                height: 72,
                fontSize: "20px",
                border: "2px solid black",
                borderRadius: "10px",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              )}
              isInputNum={true}
            />
            {/* <OtpInput
              containerStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBlock: "50px 30px"
              }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px" }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            /> */}
            {/* {otpError && <Typography  style={{ color: "red", textAlign: "center" }}>{otpError}</Typography>} */}
            {/* <Typography style={{ color: "red" }}>{otpError}</Typography> */}
            <Box sx={{ textAlign: "center" }}>
              <Button
                sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={handleVerify}
                disabled={otp?.length < 4}
              >
                Verify Account
              </Button>
            </Box>
            <Stack
              justifyContent="center"
              direction="row"
              alignItems="center"
              spacing={2}
              fontSize={"18px"}
              marginTop={"30px"}
            >
              <Typography fontSize={"inherit"}>
                Didn't receive the OTP?
              </Typography>
              <Button
                onClick={() => handleResendOtp()}
                className="black-btn"
                sx={{ textTransform: "none" }}
              >
                Resend
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
      <DealerStatusConf open={dealorStatus} setOpen={setDealorStatus} />
      <BackdropUI open={backdrop} />
      {/* <OTPLoginAuth open={OTPAuthModel} setOpen={setOTPAuthModel} /> */}
    </>
  );
}
