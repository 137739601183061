import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box, Tooltip, Typography } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { handleDownload } from './downloadFile';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
const ViewArrayItemModal = ({ open, handleClose, dataArray, dataType }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>View {dataType} <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can view and download {dataType}
                </DialogContentText>
               
                {/* Center the buttons */}
                <Box mt={2} display="flex" justifyContent="center">
                    <Box sx={{ width: "213px" }}>
                    {dataArray && dataArray.length > 0 ? (
                        dataArray.map((data, index) => (
                            <Box key={index} className="text-center">
                                <Box className="p-relative">
                                    <Tooltip title={data.files} placement="top" className="uploadedfilename">
                                        <Typography>
                                            {dataType}
                                        </Typography>
                                        <DownloadForOfflineRoundedIcon 
                                            className="my-auto" 
                                            onClick={() => handleDownload(new Date() + dataType, data.files)} 
                                        />
                                        <FullscreenIcon 
                                            className="my-auto" 
                                            onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data.files}`)} 
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body1" align="center">
                            No data found
                        </Typography>
                    )}

                    </Box>
                </Box>
                
            </DialogContent>
        </Dialog>
    );
};
export default ViewArrayItemModal;
