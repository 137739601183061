import {
    Button,
    InputAdornment,
    Stack,
    Typography,
    TextField
  } from "@mui/material";
  import Grid from "@mui/material/Grid";
  import React, { useEffect, useMemo, useRef, useState } from "react";
  import Body from "../../components/Body";
  import SearchIcon from "@mui/icons-material/Search";
  import DataTable from "../reusable/DataTable";
  import { useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import ExportExcel from "../Excelexport";
  import {
    sendInvites,
  } from "../../store/dealorOnBoarding/addApplicant";
  import BulkUpload from "../reusable/BulkUpload";
  import { convertToFormData } from "../ConvertToFormData";
  import { getGroupOutletAPI } from "../../store/dealerGroup/getGroupOutlet";
  import { outletType, subOType } from "../../jsonData/outletType";
  import { deleteDealerGroupOutletAPI } from "../../store/dealerGroup/addOutlet";
  import { getGroupDealerApi } from "../../store/dealerGroup/getGroupDealer";
  import { showToast } from "../../toast/toast";
  import { convertDateFunction } from "../../utils/dateFormater";
  import ButtonStyles from "../../styles/buttons.module.scss";
  import DeleteConfirmationModal from "../reusable/DeleteConfirmationModal";
  import downloadFile from '../../assets/downloadSheetExample/Dealer_Outlet_Format.xlsx';
  import ClearIcon from '@mui/icons-material/Clear';


  
const DealorOutlet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getApplicant = useSelector((state) => state?.getGroupOutletSliceReducer?.result);
  const getGroupUser = useSelector((state) => state?.getGroupDealorSliceReducer?.result?.data);
  const getEmailTemplate = useSelector((state) => state?.getEmailTemplateReducer);
  const cities = useSelector((state) => state?.getCitiesReducer?.result?.data);
  

  useEffect(() => {
    localStorage.removeItem("selectedId");
    localStorage.removeItem("selectedIdToCreateOutLet");
  }, []);

  const [applicantData, setApplicantData] = useState(getApplicant ? getApplicant :  []);
  const [rowsData, setRowsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const inputRef = useRef(null);


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [age, setAge] = useState("");
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const isInitialRender = useRef(true);

  const isInitialRenderApplicant = useRef(true);
  const isInitialRenderGroupUser = useRef(true);
  const isInitialRenderCities = useRef(true);

  const [deleteId, setDeleteId] = useState(null);
  const [openModal, setOpenModal] = useState(false);


  const columns = [
    { field: "trade_name", headerName: "Trade Name", width: 200 },
    { field: "region", headerName: "Region", width: 120 },
    { field: "city", headerName: "City", width: 120 },
    { field: "location", headerName: "Location", width: 120 },
    { field: "dealer_group", headerName: "Dealer Group",width: 150},
    { field: "outlet_type", headerName: "Outlet Type", width: 150 },
    { field: "facility_type", headerName: "Facility Type",width: 200 },
    { field: "facility_category", headerName: "Facility Category", width: 200 },
  ];

  const handleNavigate = () => navigate("/users/add-dealer-outlet");

  const handleChange = (event) => setEmailTemplate(event.target.value);

  const editUser = () => navigate("/users/add-dealer-outlet");


  const deleteUser = async (id) => {
    setOpenModal(true);
    setDeleteId(id)
  };

  const handleCloseModal = () => {
      setOpenModal(false);
  };

  const dowanloadExcel = () => {
    const link = document.createElement('a');
      link.href = downloadFile;
      link.download = 'dealerOutletExample.xls'; // Optional, defaults to file name
      link.click();
  }
  const handleConfirmDelete = async() => {
      const deleteData = await dispatch(deleteDealerGroupOutletAPI(deleteId));
      if(deleteData?.error){
        const { message } = deleteData?.payload;
      }else{
        showToast(deleteData?.payload?.message, 1);
        dispatch(getGroupOutletAPI());
        setDeleteId(null)
        handleCloseModal()
      }
  };

  const [searchText, setSearchText] = useState("");
    const handleChangeSearch = (e) => {
        e.preventDefault();
        const searchTerm = e.target.value.toLowerCase(); 
        setSearchText(searchTerm);
    };
  const searchData = (searchText) => {
    if (!searchText) {
      setFilteredData(rowsData);
      return;
    }
    const filteredData = rowsData.filter((row) => {
      const trade_name = (row.trade_name || "").toLowerCase();
      const dealer_group = (row.dealer_group || "").toLowerCase();
      const outlet_type = (row.outlet_type || "").toLowerCase();
      const facility_category = (row.facility_category || "").toLowerCase();
      const facility_type = (row.facility_type || "").toLowerCase();
      const location = (row.location || "").toLowerCase();
      const city = (row.city || "").toLowerCase();
      const region = (row.region || "").toLowerCase();

      return (
        trade_name.includes(searchText) ||
        dealer_group.includes(searchText) ||
        outlet_type.includes(searchText) ||
        facility_category.includes(searchText) ||
        facility_type.includes(searchText) || 
        location.includes(searchText) ||
        city.includes(searchText) ||
        region.includes(searchText)
      );
    });

    setFilteredData(filteredData);
  };

  useEffect(() => {
    searchData(searchText);
  }, [searchText]);

  const handleSelectedRows = (data) => setSelectedRows(data);
  const extractEmailsFromObjects = (arrayOfObjects, selectedIds) => {
    const filteredObjects = arrayOfObjects.filter((obj) => selectedIds.includes(obj.id));
    return filteredObjects.map((obj) => obj.email).join(", ");
  };

  const sendInvite = async () => {
    const filteredEmails = extractEmailsFromObjects(applicantData, selectedRows);
    const dataToBeSent = {
      email_template_id: emailTemplate,
      recipients_list: filteredEmails,
      attachments: "",
      invites_model_id: 1,
    };
    await dispatch(sendInvites(convertToFormData(dataToBeSent)));
  };

 useEffect(() => {
      dispatch(getGroupOutletAPI());
      dispatch(getGroupDealerApi());
  }, [dispatch]);

  useEffect(() => {
      const processData = (applicant) => {
        const dealerGroup = getGroupUser?.find(group => group.dealer_group_name === applicant.dealer_group_name);
        const outletList = outletType?.find(outlet => outlet.id === applicant.outlet_type_id);
        const subOutletType = subOType?.find(subOutlet => subOutlet.facility_type === applicant.facility_type_name);
        return {
          id: applicant.id,
          trade_name: applicant.trade_name || '',
          region: applicant.region_name || '',
          city: applicant?.city_name  || '',
          location: applicant.location || '',
          dealer_group: dealerGroup?.dealer_group_name || '',
          outlet_type: outletList?.outlet_type || '',
          facility_type: subOutletType?.facility_type || '',
          facility_category: Array.isArray(applicant.facility_category) ? applicant.facility_category.join(', ') : '',
        };
      };

      const newData = applicantData?.map(processData);
      setRowsData(newData);
      setFilteredData(newData);
      const dataDownload = applicantData?.map((applicant) => {
        const data = processData(applicant);
        return {
          'Trade Name': data.trade_name,
          'Region': data.region,
          'State': applicant.state_name || '',
          'City': data.city,
          'Location': data.location,
          'Tier': applicant.tier_name || '',
          'Dealer Group': data.dealer_group,
          'Outlet Type': data.outlet_type,
          'Facility Type': data.facility_type,
          'Facility Category': data.facility_category,
          'CIN': applicant.CIN || '',
          'DA Date': convertDateFunction(applicant.DA_date) || '',
          'DA Effective Date': convertDateFunction(applicant.DA_effective_date) || '',
          'DA Number': applicant.DA_number || '',
          'DA Status': applicant.DA_status || '',
          'GST': applicant.GST || '',
          'LOA Date': convertDateFunction(applicant.LOA_date) || '',
          'LOA Number': applicant.LOA_number || '',
          'LOA Status': applicant.LOA_status || '',
          'LOI Date': convertDateFunction(applicant.LOI_date) || '',
          'LOI Number': applicant.LOI_number || '',
          'LOI Status': applicant.LOI_status || '',
          'PAN': applicant.PAN || '',
          'Appointment Date': convertDateFunction(applicant.appointment_date) || '',
          'Closure Date Showroom': convertDateFunction(applicant.closure_date_showroom) || '',
          'Closure Date Workshop': convertDateFunction(applicant.closure_date_workshop) || '',
          'DC Fast Charger': applicant.dc_fast_charger || '',
          'Elevation Type': applicant.elevation_type || '',
          'EV Dealer Showroom 1 KW': applicant.ev_dealer_showroom_11_KW || '',
          'EV Dealer Workshop 11 KW': applicant.ev_dealer_workshop_11_KW || '',
          'EV Display Zone': applicant.ev_display_zone || '',
          'Green Dealer': applicant.green_dealer || '',
          'Lease Expiry Date Showroom': convertDateFunction(applicant.lease_expiry_date_showroom) || '',
          'Lease Expiry Date Workshop': convertDateFunction(applicant.lease_expiry_date_workshop) || '',
          'Location': applicant.location || '',
          'Main Dealer Code': applicant.main_dealer_code || '',
          'Master Code DMS': applicant.master_code_DMS || '',
          'Number of Bays': applicant.number_bp_bays || '',
          'Number of Display Car': applicant.number_of_display_cars || '',
          'Number of Mech Bays': applicant.number_of_mech_bays || '',
          'Outlet Code': applicant.outlet_code || '',
          'Ownership Showroom': applicant.ownership_showroom || '',
          'Ownership Workshop': applicant.ownership_workshop || '',
          'Project Type Showroom': applicant.project_type_showroom || '',
          'Project Type Workshop': applicant.project_type_workshop || '',
          'Registered Address': applicant.registered_address || '',
          'Registered Company Name': applicant.registered_company_name || '',
          'Remarks': applicant.remarks || '',
          'Showroom Address': applicant.showroom_address || '',
          'Showroom Covered Area In Sq Ft': applicant.showroom_covered_area_in_sq_ft || '',
          'Showroom Frontage In Ft': applicant.showroom_frontage_in_ft || '',
          'Showroom Latitude': applicant.showroom_latitude || '',
          'Showroom Longitude': applicant.showroom_longitude || '',
          'Showroom Pincode': applicant.showroom_pincode || '',
          'Showroom Plot Area In Sq Ft': applicant.showroom_plot_area_in_sq_ft || '',
          'Start Of Operation Target Date Showroom': convertDateFunction(applicant.start_of_operation_target_date_showroom) || '',
          'Start Of Operation Target Date Workshop': convertDateFunction(applicant.start_of_operation_target_date_workshop) || '',
          'Status': applicant.status || '',
          'Workshop Address': applicant.workshop_address || '',
          'Workshop Covered Area In Sq Ft': applicant.workshop_covered_area_in_sq_ft || '',
          'Workshop Frontage In Ft': applicant.workshop_frontage_in_ft || '',
          'Workshop Latitude': applicant.workshop_latitude || '',
          'Workshop Longitude': applicant.workshop_longitude || '',
          'Workshop Pincode': applicant.workshop_pincode || '',
          'Workshop Plot Area In Sq Ft': applicant.workshop_plot_area_in_sq_ft || '',
        };
      });

      setDataToDownload(dataDownload);
  
  }, [applicantData, getGroupUser, cities]);

  useEffect(() => {
    setApplicantData(getApplicant);
  }, [getApplicant]);

    return (
      <>
       
        <Body>
          <Grid container alignItems="center" flexWrap={"wrap"} spacing={1}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ my: 2 }}
                alignItems="center"
              >
                <svg
                  width="20"
                  height="25"
                  viewBox="0 0 20 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 3.80005H0V24.8001H17V21.8001H20V0.800049H3V3.80005ZM16 23.8001H1V4.80005H3H12.5V8.30005H16V21.8001V23.8001ZM13.5 5.50705L15.293 7.30005H13.5V5.50705ZM4 1.80005H19V20.8001H17V7.59305L13.207 3.80005H4V1.80005Z"
                    fill="#05141F"
                  />
                  <path
                    d="M8.5 7.30029H2.5V13.3003H8.5V7.30029ZM7.5 12.3003H3.5V8.30029H7.5V12.3003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 9.7998H10V10.7998H14.5V9.7998Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 12.2998H10V13.2998H14.5V12.2998Z"
                    fill="#05141F"
                  />
                  <path
                    d="M10 14.8003H2.5V15.8003H10V14.8003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 14.8003H11.5V15.8003H14.5V14.8003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M5.5 17.3003H2.5V18.3003H5.5V17.3003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 17.3003H7V18.3003H14.5V17.3003Z"
                    fill="#05141F"
                  />
                  <path
                    d="M9.5 19.7998H2.5V20.7998H9.5V19.7998Z"
                    fill="#05141F"
                  />
                  <path
                    d="M14.5 19.7998H11V20.7998H14.5V19.7998Z"
                    fill="#05141F"
                  />
                </svg>
                <Typography variant="h6">Dealers Outlet</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <Grid
                container
                flexWrap={"nowrap"}
                spacing={1}
                justifyContent={"end"}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  sx={{
                    marginRight: {
                      md: 0.5,
                      sm: 0,
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" onClick={() => setSearchText("")}>
                            { searchText?.length > 0 ? <ClearIcon style={{cursor:"pointer"}} /> : ""}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search by Group Name, Outlet Type and Facility Type"
                    value={searchText}
                    onChange={handleChangeSearch}
                  />
                </Grid>
                <Grid item sm="auto">
                  <Button variant="contained" onClick={handleNavigate}>
                    Add Dealers Outlet
                  </Button>
                </Grid>
                <Grid item sm="auto">
                  {" "}
                  {/* <input
                      type="file"
                      ref={inputRef}
                      style={{ display: "none" }}
                      onChange={handleFileUpload}
                    /> */}
                  <Button
                    variant="contained"
                    onClick={() => setBulkUploadModal(true)}
                  >
                    Bulk Upload Dealers Outlet
                  </Button>{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid container>
            <Grid item xs={12} sx={{ overflowX: "auto", widt: "100%" }}>
              <DataTable
                data={filteredData ? filteredData : []}
                columns={columns}
                deleteUser={deleteUser}
                editUser={editUser}
                handleSelectedRows={handleSelectedRows}
                type="dealerOutlet"
              />
            </Grid>
  
            <Grid container spacing={1} my={2}> 
                    

            <Grid item xs={12} md={5}>
              <Grid container spacing={2} marginBlockEnd={3}>
                <Grid item xs="6">
                  
                </Grid>
                <Grid item xs="6">
                  
                </Grid>
              </Grid>
            </Grid>
              
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBlockstart: "20px",
                }}
              >
              {filteredData?.length > 0 && <>
                <ExportExcel
                  excelData={dataToDownload}
                  fileName={new Date() + 'Outlet'}
                />
              </>}
                
  
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  //onChange={handleFileUpload}
                />
                <Button
                className={ButtonStyles.download}
                  //onClick={handleBulkUpload}
                  variant="outlined"
                  sx={{
                    background: "#D7EDFE",
                    border: "1px solid #B2D3ED",
                    color: "#05141F",
                    "& svg": {
                      marginRight: "5px",
                    },
                    "&:hover": {
                      background: "#000",
                      color: "#fff",
                      "& svg": {
                        "& path": {
                          fill: "white !important",
                        },
                      },
                    },
                  }}
                >
                  {" "}
                  <svg
                    width="16"
                    height="23"
                    viewBox="0 0 16 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.35294 1.54762C1.57324 1.54762 0.941176 2.25117 0.941176 3.11905V19.881C0.941176 20.7488 1.57324 21.4524 2.35294 21.4524H13.6471C14.4267 21.4524 15.0588 20.7488 15.0588 19.881V8.2732C15.0588 7.81446 14.8788 7.37863 14.5658 7.08007L9.16358 1.92594C8.90777 1.68181 8.58183 1.54762 8.24489 1.54762H2.35294ZM0 3.11905C0 1.67259 1.05345 0.5 2.35294 0.5H8.24489C8.80649 0.5 9.34965 0.723646 9.77609 1.13052L15.1784 6.28467C15.6999 6.78224 16 7.50864 16 8.2732V19.881C16 21.3274 14.9465 22.5 13.6471 22.5H2.35294C1.05345 22.5 0 21.3274 0 19.881V3.11905Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.4707 5.21256V1.5459H9.41188V5.21256C9.41188 6.08044 10.044 6.78399 10.8236 6.78399H15.0589V7.83161H10.8236C9.52416 7.83161 8.4707 6.65902 8.4707 5.21256Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.7648 18.3098H4.23535V17.2622H11.7648V18.3098Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.47096 9.4043V15.1662H7.52979V9.4043H8.47096Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.6673 9.03233C7.85102 8.82776 8.14899 8.82776 8.3328 9.03233L11.1563 12.1751L10.4908 12.916L8.00001 10.1435L5.50927 12.916L4.84375 12.1751L7.6673 9.03233Z"
                      fill="black"
                    />
                  </svg>
                  Upload
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} marginTop={2} marginBottom={3}>
              <hr sx={{ BorderBottomColor: "#000", Opacity: "0.2" }} />
            </Grid>
          </Grid>
        </Body>
        <BulkUpload open={bulkUploadModal} setOpen={setBulkUploadModal} type="outlet" downloadFile={dowanloadExcel} />
        <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}  handleConfirm={handleConfirmDelete}  deleteType='Dealer Outlet'/>
      </>
    );
  };
  
  export default DealorOutlet;
  