import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getDesignEstimate } from '../../store/home/designEstimate';
import { convertToFormData } from '../ConvertToFormData';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { handleDownload } from '../reusable/downloadFile';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function DesignEstimate() {
    localStorage.removeItem("isRevisedViewClick")
    localStorage.removeItem("isRevisedGFCViewClick")
    localStorage.removeItem("isRevisedGFCViewClick");
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const [open, setOpen] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();


    const [openInvoice, setOpenInvoice] = useState({});
    const [anchorElInvoice, setAnchorElInvoice] = useState(null);
    const [placementInvoice, setPlacementInvoice] = useState();


    const handleClickPopper = (newPlacement, index) => (event) => {
        if (event) {
            setAnchorEl(event.currentTarget);
        }
        setOpen((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
        setPlacement(newPlacement);
    };

    const handleClickPopperInvoice = (newPlacement, index) => (event) => {
        if (event) {
            setAnchorElInvoice(event.currentTarget);
        }
        setOpenInvoice((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
        setPlacementInvoice(newPlacement);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const designEstimate = async () => {
        const formD = {
            'form_id': formId?.data?.id
        };
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getDesignEstimate(data));
            setData(response?.payload?.data);
        } catch (error) {
            
        }
    };

    const handleClose = (index) => {
        const updatedOpen = [...open];
        updatedOpen[index] = false;
        setOpen(updatedOpen);
      };

    useEffect(() => {
        designEstimate();
    }, []);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">Design Estimates</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Status</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Slip</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Invoice</StyledTableCell>    
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row, index) => (
                                    <StyledTableRow key={row?.name}>
                                        <StyledTableCell component="th" scope="row">{row.estimate_type}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.created_at.split("T")[0]}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row?.payment_status === false ? (
                                                <Button className="notdoneBtn">Not Done</Button>
                                            ) : (
                                                <Button className="doneBtn">Done</Button>
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.payment_slip === null ? (
                                                <Button className="notdoneBtn">Request</Button>
                                            ) : (
                                                <Button className="DownloadBtn" onClick={handleClickPopper('right', index)}>View</Button>
                                            )}
                                            <Popper id={id} open={open[index]} anchorEl={anchorEl} transition placement={placement}>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid',
                                                                borderColor: 'grey.300',
                                                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                p: 1,
                                                                bgcolor: 'background.paper',
                                                                borderRadius: 0,
                                                                width: 300,
                                                                height: 300,
                                                                position: 'relative',
                                                            }}
                                                        >
                                                        <button
                                                            onClick={() => handleClickPopper(placement, index)(null)} // Pass null to close the popper
                                                            style={{
                                                                position: 'absolute',
                                                                top: 5,
                                                                right: 5,
                                                                background: 'none',
                                                                border: 'none',
                                                                fontSize: '16px',
                                                                cursor: 'pointer',
                                                            }}
                                                            >
                                                            ✕
                                                        </button>
                                                            <p>UTR No. {row?.utr_no}</p>
                                                            {row?.payment_slip === null ?
                                                                <p>Payment not done</p> :
                                                                <>
                                                                <Box key={row?.payment_slip} className="text-center">
                                                                    <Box className="p-relative">
                                                                        <Tooltip title={row?.payment_slip} placement="top" className={` uploadedfilename`}>
                                                                            <Typography>{'Payment File'}</Typography>
                                                                            <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'payment_slip', row?.payment_slip)} />
                                                                            <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${row?.payment_slip}`)} />
                                                                        </Tooltip>
                                                                    </Box>
                                                                </Box>
                                                                </>
                                                               
                                                            }
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.Invoice === 1 ? (
                                                <Button className="notdoneBtn">Request</Button>
                                            ) : (
                                                <Button className="DownloadBtn" onClick={handleClickPopperInvoice('right', index)}>View</Button>
                                            )}
                                            <Popper id={id} open={openInvoice[index]} anchorEl={anchorElInvoice} transition placement={placementInvoice}>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid',
                                                                borderColor: 'grey.300',
                                                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                p: 1,
                                                                bgcolor: 'background.paper',
                                                                borderRadius: 0,
                                                                width: 300,
                                                                height: 300,
                                                                position: 'relative',
                                                            }}
                                                        >
                                                        <button 
                                                            onClick={() => handleClickPopperInvoice(placementInvoice, index)(null)}
                                                            style={{ position: 'absolute',top: 5,right: 5,background: 'none',border: 'none',fontSize: '16px',cursor: 'pointer',}}
                                                        >
                                                            ✕
                                                        </button>
                                                        <p>Invoice</p>
                                                            {row?.invoice === null ?
                                                                <p>Invoice not uploaded</p> :
                                                                <>
                                                                    <Box key={row?.invoice} className="text-center">
                                                                        <Box className="p-relative">
                                                                            <Tooltip title={row?.invoice} placement="top" className={` uploadedfilename`}>
                                                                                <Typography>{'Invoice File'}</Typography>
                                                                                <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'invoice_slip', row?.invoice)} />
                                                                                <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${row?.invoice}`)} />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            }
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </StyledTableCell>
                                      
                                        <StyledTableCell align="center">
                                        <Button className="DownloadBtn" title='Download Design Estimate' onClick={() => handleDownload(new Date() + 'estimate', row?.estimate)}>Download</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}
