import React, { useEffect, useState } from "react";
import Appbar from "../../components/Appbar";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Drawer from "../../components/Drawer";
import Body from "../../components/Body";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import Style from "../../styles/home.module.scss";
import StyleUser from "../../styles/profile.module.scss";
import FormStyle from "../../styles/field.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { convertToFormData } from "../ConvertToFormData";
import { addInnoceansUserSchema } from "../validationSchemas";
import { getInnoceansUserApi, innoceanUserActive } from "../../store/innoceanUser/getInnoceanUser";
import { addInnoceansUserApi, editInnoceansUserApi } from "../../store/innoceanUser/addInnoceansUser";
import { showToast } from "../../toast/toast";
import { getRegionForOutletApi } from "../../store/dealerGroup/getRegionForOutlet";
import { getStateForOutletApi } from "../../store/dealerGroup/getStateForOutlet";
import { getCitiesForOutletApi } from "../../store/dealerGroup/getCitiesForOutlet";
import { getAllUserRoleApi } from "../../store/rolesAcessManagement/getRoles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function AddInnoceansUser() {
    const loginSelector = useSelector((state) => state?.login);
    const { loading } = loginSelector;
    const getInnoceansUser = useSelector((state) => state?.getInnoceansUserSliceReducer?.result?.data);
    const getAllRoles = useSelector((state) => state?.getUserRoleReducer?.userTypeData?.data);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [profileImage, setProfileImage] = useState(null);
    const [addInnoceansUserRequest, setAddInnoceansUserRequest] = useState();
    const location = useLocation();
    const isEditInnoceanUserPage = localStorage.getItem("selectedId") ? true : false;
    const [showPassword, setShowPassword] = useState(false);

    const updateError = (name)=>{
        setErrors((preValue) => {
            return {
                ...preValue,
                [name] : ""
            }
        })
    }


    const handleChange = (event) => {
        const value = event.target.value;
        setAddInnoceansUserRequest(prevState => ({
            ...prevState,
            [event.target.name]: value
        }));
        updateError(event.target.name)
    };

    const handleDeactivateAccount = (id, activeState) => {
        const formData = {
            "user_id": id,
            "user_status": activeState
        }
        dispatch(innoceanUserActive(formData));
    };

    useEffect(() => {
        if (localStorage.getItem("selectedId") && getInnoceansUser) {
            const EditData = localStorage.getItem("selectedId") ? getInnoceansUser?.filter((row, index) => row.user_id == localStorage.getItem('selectedId')) : [];
            setAddInnoceansUserRequest(prevState => ({
                ...prevState,
                ...EditData?.[0]
            }));
        }
    }, [localStorage.getItem("selectedId"), getInnoceansUser]);
    


    const numberVerify = () => {
        
    };

    const emailVerify = () => {
       
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAddInnoceansUserRequest(prevState => ({
            ...prevState,
            image: file
        }));

        const fileNew = event.target.files[0];

        if (fileNew) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(fileNew);
        }
    };


    const addRequestAPI = async () => {
        const data = convertToFormData(addInnoceansUserRequest)
        const addInnoceansUserResponse = await dispatch(addInnoceansUserApi(data));
        if (addInnoceansUserResponse?.payload?.status === "error") {
            showToast(addInnoceansUserResponse?.payload?.message, 2);
            return;
        }
        showToast(addInnoceansUserResponse?.payload?.message, 1)
        dispatch(getInnoceansUserApi());
        navigate('/users/innoceans')
    }

    const editRequestAPI = async () => {
        const data = convertToFormData(addInnoceansUserRequest)
        if (profileImage == null) {
            data.delete('image');
        }
        const addInnoceansUserResponse = await dispatch(editInnoceansUserApi(data));
        if (addInnoceansUserResponse?.payload?.status === "error") {
            showToast(addInnoceansUserResponse?.payload?.message, 2);
            return;
        }
        showToast(addInnoceansUserResponse?.payload?.message, 1)
        dispatch(getInnoceansUserApi());
        navigate('/users/innoceans')
    }
    useEffect(() => {
        if (localStorage.getItem("selectedId") && getInnoceansUser) {
            const EditData = localStorage.getItem("selectedId") ? getInnoceansUser?.filter((row, index) => row.user_id == localStorage.getItem('selectedId')) : []
            setAddInnoceansUserRequest({
                'first_name': EditData?.[0]?.user_first_name,
                'last_name': EditData?.[0]?.user_last_name,
                'email': EditData?.[0]?.user_email,
                'phone': EditData?.[0]?.user_phone,
                'address': EditData?.[0]?.address,
                'city_id': EditData?.[0]?.city_id,
                'pin_code': EditData?.[0]?.pin_code,
                'state_id': EditData?.[0]?.state_id,
                'image': EditData?.[0]?.user_image,
                'is_active': EditData?.[0]?.user_is_active,
                'is_prime_vendor': EditData?.[0]?.user_is_prime_vendor,
                'zone_id': EditData?.[0]?.zone_id,
                'user_id':EditData?.[0]?.user_id
            })
        }

    }, [localStorage.getItem("selectedId"), getInnoceansUser])

    useEffect(() => {
        if(localStorage.getItem("selectedId") !== null && localStorage.getItem("selectedId") !== "" && localStorage.getItem("selectedId")!==undefined){
            dispatch(getInnoceansUserApi());
        }
        
    },[])

    useEffect(() => {
        if (!getInnoceansUser)
          dispatch(getInnoceansUserApi())
      }, [getInnoceansUser]);

    const handleSubmit = (e) => {
        e.preventDefault();
      
        const context = {
          selectedId: localStorage.getItem("selectedId"),
        };
      
        addInnoceansUserSchema.validate(addInnoceansUserRequest, { abortEarly: false, context })
          .then(() => {
            if (context.selectedId) {
              editRequestAPI();
            } else {
              addRequestAPI();
            }
            setErrors({});
          })
          .catch((validationErrors) => {
            if (validationErrors && validationErrors.inner) {
              const newErrors = {};
              validationErrors.inner.forEach((error) => {
                newErrors[error.path] = error.message;
              });
              setErrors(newErrors);
            } else {
              console.error("Validation error structure unexpected:", validationErrors);
              setErrors({}); // Reset errors state
            }
          });
      };
    
     //Get all regions
    const regionList = useSelector((state) => state?.getRegionForOutletReducer?.result?.regions);
    useEffect(() => {
       const response = dispatch(getRegionForOutletApi())
    },[dispatch]);

    //get State based on region
    const stateList = useSelector((state) => state?.getStateForOutletReducer?.result?.states);
    useEffect(() => {
        if(addInnoceansUserRequest?.zone_id){
            const stateRequest = {
                "region_id" : addInnoceansUserRequest?.zone_id
            }
            const response = dispatch(getStateForOutletApi(stateRequest))
        }
    },[addInnoceansUserRequest?.zone_id])

    //Get cities based on state
    const cityList = useSelector((state) => state?.getCitiesForOutletReducer?.result?.cities);
    useEffect(() => {
        if(addInnoceansUserRequest?.state_id){
            const regionRequest = {
                "state_id" : addInnoceansUserRequest?.state_id
            }
            const response = dispatch(getCitiesForOutletApi(regionRequest))
        }
    },[addInnoceansUserRequest?.state_id])

    useEffect(() => {
        dispatch(getAllUserRoleApi())
    },[dispatch])
    return (
        <>
            <Body>
            {/* <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                    {'<'}
                </span> */}
                <KeyboardArrowLeftIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />

                <form
                    onSubmit={handleSubmit}
                >
                    <Box className={Style?.headerSection}>

                        <Typography
                            color="#fff"
                            fontWeight={500}
                            variant="h6"
                            marginInlineStart={3}
                        >
                            Personal Information
                        </Typography>
                    </Box>
                    <Grid container marginBlockStart={2} spacing={2}>
                        <Grid
                            item
                            lg={9}
                            md={8}
                            sm={12}
                            xs={12}
                            sx={{ marginBlockEnd: "40px" }}
                        >
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        First Name
                                    </Typography>
                                    <TextField
                                        error={Boolean(errors.first_name)}
                                        helperText={errors.first_name}
                                        value={addInnoceansUserRequest?.first_name}
                                        onChange={handleChange}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="first_name"
                                        sx={{ marginTop: "10px" }}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Last Name
                                    </Typography>
                                    <TextField
                                        error={Boolean(errors.last_name)}
                                        helperText={errors.last_name}
                                        value={addInnoceansUserRequest?.last_name}
                                        onChange={handleChange}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="last_name"
                                        sx={{ marginTop: "10px" }}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Email
                                    </Typography>

                                    <TextField
                                         error={Boolean(errors.email)}
                                         helperText={errors.email}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="email"
                                        sx={{ marginTop: "10px" }}
                                        value={addInnoceansUserRequest?.email}
                                        onChange={handleChange}
                                        disabled={(localStorage?.getItem("selectedId") && localStorage.getItem("selectedId") !== "") ? true : false}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        onClick={emailVerify}
                                                        variant="contained"
                                                        sx={{
                                                            bgcolor: "#D9EAF6",
                                                            color: "#05141F",
                                                            "&:hover": { bgcolor: "#000", color: "#fff" },
                                                        }}
                                                    >
                                                        Verify
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Password
                                    </Typography>
                                    <OutlinedInput
                                        id="input-password"
                                        variant="outlined"
                                        fullWidth
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        sx={{ marginTop: "10px" }}
                                        value={addInnoceansUserRequest?.password}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 10 }}
                                        endAdornment={
                                            showPassword ? (
                                            <IconButton onClick={() => setShowPassword(false)}>
                                                <Visibility />
                                            </IconButton>
                                            ) : (
                                            <IconButton onClick={() => setShowPassword(true)}>
                                                <VisibilityOff />
                                            </IconButton>
                                            )
                                        }
                                        
                                    />
                                    <FormHelperText error>
                                        {errors?.password}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Mobile Number
                                    </Typography>
                                    <TextField
                                        error={Boolean(errors.phone)}
                                        helperText={errors.phone}
                                        type="text"
                                        variant="outlined"
                                        value={addInnoceansUserRequest?.phone}
                                        name="phone"
                                        fullWidth
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 10 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography variant="p">+91 | </Typography>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        onClick={numberVerify}
                                                        variant="contained"
                                                        sx={{
                                                            bgcolor: "#D9EAF6",
                                                            color: "#05141F",
                                                            "&:hover": { bgcolor: "#000", color: "#fff" },
                                                        }}
                                                    >
                                                        Verify
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ marginTop: "10px" }}

                                    />
                                </Grid>
                                
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Zone
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="demo-simple-select"
                                            value={addInnoceansUserRequest?.zone_id ? addInnoceansUserRequest?.zone_id : ""}
                                            onChange={handleChange}
                                            name="zone_id"
                                            sx={{ marginTop: "10px" }}
                                        >
                                            <MenuItem value="">Select Zone</MenuItem>
                                            {regionList?.map((row) => (
                                                <MenuItem key={row.key} value={row.id}>{row.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>
                                            {errors?.zone_id}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        State
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="demo-simple-select"
                                            value={addInnoceansUserRequest?.state_id ? addInnoceansUserRequest?.state_id : ""}
                                            onChange={handleChange}
                                            name="state_id"
                                            sx={{ marginTop: "10px" }}
                                        >
                                            <MenuItem value="">Select State</MenuItem>
                                            {stateList?.map((row) => (
                                                <MenuItem key={row.key} value={row.id}>{row.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>
                                            {errors?.state_id}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                                City
                                            </Typography>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="demo-simple-select"
                                                    value={addInnoceansUserRequest?.city_id ? addInnoceansUserRequest?.city_id : ""}
                                                    name="city_id"
                                                    onChange={handleChange}
                                                    sx={{ marginTop: "10px" }}
                                                >
                                                    <MenuItem value="">Select City</MenuItem>
                                                    {cityList?.map((row) => (
                                                        <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error>
                                                    {errors?.city_id}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                                Pincode
                                            </Typography>
                                            <TextField
                                                error={Boolean(errors.pin_code)}
                                                helperText={errors.pin_code}
                                                type="text"
                                                variant="outlined"
                                                value={addInnoceansUserRequest?.pin_code}
                                                name="pin_code"
                                                onChange={handleChange}
                                                fullWidth
                                                sx={{ marginTop: "10px" }}
                                                inputProps={{ maxLength: 6 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Address
                                    </Typography>
                                    <TextField
                                        error={Boolean(errors.address)}
                                        helperText={errors.address}
                                        id="input"
                                        variant="outlined"
                                        fullWidth
                                        name="address"
                                        sx={{ marginTop: "10px" }}
                                        value={addInnoceansUserRequest?.address}
                                        onChange={handleChange}
                                        inputProps={{ maxLength: 80 }}
                                    />
                                </Grid>
                                
                                {/* <Grid item md={6} xs={12}>
                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Role
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="demo-simple-select"
                                            value={addInnoceansUserRequest?.role ? addInnoceansUserRequest?.role : ""}
                                            onChange={handleChange}
                                            name="role"
                                            sx={{ marginTop: "10px" }}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {getAllRoles?.map((row) => (
                                                <MenuItem key={row.key} value={row.id}>{row.name}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error>
                                            {errors?.role}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            md={4}
                            sm={12}
                            xs={12}
                            sx={{ marginBlockEnd: "40px" }}
                        >
                            <Card className={StyleUser?.card}>
                                <Box className={StyleUser.profileSection}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        badgeContent={
                                            <IconButton className={StyleUser.uploadicon}>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: "none", margin: "0" }}
                                                    id="raised-button-file"
                                                    type="file"
                                                    onChange={handleFileChange}
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <CameraAltIcon color="dark" />
                                                </label>
                                            </IconButton>
                                        }
                                    >
                                        <Avatar
                                            alt="Profile Picture"
                                            src={
                                                profileImage
                                                    ? profileImage
                                                    : `${process.env.REACT_APP_BASE_URL}${addInnoceansUserRequest?.image}`
                                            }
                                            sx={{
                                                width: "150px",
                                                height: "150px",
                                                border: "3px solid #fff",
                                            }}
                                            className={StyleUser?.profileImg}
                                        />
                                    </Badge>
                                </Box>
                                <Box component={"div"} className={StyleUser?.profileInfo}>
                                    {/* <Typography
                                        component="h2"
                                        fontWeight="500"
                                        color="#051639"
                                        variant="h6"
                                        sx={{
                                            marginBottom: "8px",
                                        }}
                                    >
                                        {addInnoceansUserRequest?.first_name || ""} {addInnoceansUserRequest?.last_name || ""}
                                    </Typography> */}
                                    <Typography
                                        component="h2"
                                        fontWeight="700"
                                        color="#051639"
                                        variant="h6"
                                        sx={{
                                            marginBottom: "8px",
                                        }}
                                    >
                                        {addInnoceansUserRequest?.role || ""}
                                    </Typography>
                                </Box>
                            </Card>
                            {isEditInnoceanUserPage && (
                                <Button
                                    variant={addInnoceansUserRequest?.is_active ? "outlined" : "success"}
                                    // variant="success"
                                    size="large"
                                    sx={{
                                        padding: "15px 10px",
                                        fontSize: "15px",
                                        borderRadius: "8px",
                                        backgroundColor: "white",
                                        color: addInnoceansUserRequest?.is_active ? "red" : "green",
                                        border: "1px solid black",
                                        fontWeight: "bold",
                                        marginBlock: "20px",
                                        whiteSpace: "nowrap",
                                        width: "100%",
                                    }}
                                    // onClick={handleDeactivateAccount}
                                    onClick={() =>
                                        handleDeactivateAccount(
                                            addInnoceansUserRequest?.user_id,
                                            !addInnoceansUserRequest?.is_active
                                        )
                                    }
                                >
                                    {addInnoceansUserRequest?.is_active
                                        ? "Deactivate Account"
                                        : "Activate Account"}

                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="end">
                        <Grid item xs={"auto"} gap={2} display={"flex"} marginBlock={5}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => navigate(-1)}
                                sx={{ padding: "15px 70px", fontSize: "17px", borderRadius: "8px", }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ padding: "15px 70px", fontSize: "17px", borderRadius: "8px", backgroundColor: "black" }}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Body>
        </>
    );
}

export default AddInnoceansUser;