import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  Typography,
  Stack,
  FormHelperText,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import Body from "../../../components/Body";
import FormStyle from "../../../styles/field.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addDepartmentApi,
  editDepartmentApi,
} from "../../../store/department/addDepartment";
import { getDepartment } from "../../../store/department/getDepartment";
import { addOrganizationSchema } from "../../validationSchemas";
import { showToast } from "../../../toast/toast";
import {
  addOrganizationApi,
  editOrganizationApi,
} from "../../../store/organization/addOrganization";
import { getOrganizationApi } from "../../../store/organization/getOrganization";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';


export default function AddOrganization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [addOrganizationRequest, setAddOrganizationData] = useState({});
  
  const getAllOrganization = useSelector(
    (state) => state?.organizationReducer?.result
  );
  const { loading } = getAllOrganization;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddOrganizationData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    updateError(name);
  };

  const updateError = (name) => {
    setErrors((prevValue) => ({
      ...prevValue,
      [name]: "",
    }));
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const addRequestAPI = async () => {
    const dataToBeSent = { name: addOrganizationRequest?.name.trim(), is_direct: addOrganizationRequest?.is_direct==='yes' ? true : false };
    try {
      const addOrganizationResponse = await dispatch(addOrganizationApi(dataToBeSent));
      if(addOrganizationResponse?.payload?.status === "error"){
        showToast("Organization already exist", 2);
        return
      }
      showToast("Organization added successfully", 1);
      navigate("/master/organization");
    } catch (err) {
      showToast("Something went wrong", 2);
    }
  };

  const editRequestAPI = async () => {
    const dataToBeSent = { name: addOrganizationRequest?.name.trim(), is_direct: addOrganizationRequest?.is_direct==='yes' ? true : false };
    try {
      const editOrganizationResponse = await dispatch(editOrganizationApi(dataToBeSent));
      if(editOrganizationResponse?.payload?.status === "error"){
        showToast("Organization already exist", 2);
        return
      }
      showToast("Organization updated successfully", 1);
      navigate("/master/organization");
    } catch (err) {
      showToast("Something went wrong", 2);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addOrganizationSchema
      .validate(addOrganizationRequest, { abortEarly: false })
      .then(() => {
        const selectedId = localStorage.getItem("selectedId");
        if (selectedId) {
          editRequestAPI();
        } else {
          addRequestAPI();
        }
        setErrors({});
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      });
  };
  useEffect(() => {
    const selectedId = localStorage.getItem("selectedId");
    if (selectedId && getAllOrganization) {
      const EditData = getAllOrganization.filter(
        (row) => row.id === +selectedId
      );
      setAddOrganizationData({ name: EditData[0]?.name ,is_direct: EditData[0]?.is_direct===true ? 'yes' : 'no' });
    }
  }, [localStorage.getItem("selectedId"), getAllOrganization]);

  return (
        <>
            <Body >
              <Box sx={{display: 'flex',flexDirection:"column",height:"100%"}}>

                {/* <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                    {'<'}
                </span> */}
              <KeyboardArrowLeftIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />

                <form onSubmit={handleSubmit}>
                
                    <Grid container alignItems="center" flexWrap="wrap" spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ my: 2 }}
                                alignItems="center"
                            >
                                <CorporateFareIcon/>
                                <Box className="d-flex">

                                <Typography variant="h6" className="pageTitle d-flex">{localStorage?.getItem("selectedId") ? "Edit " : "Create "} Supplier Organization</Typography>
                                {/* <Typography variant="h6" className="pageTitle d-flex" sx={{width:"max-content"}}>Supplier Organization</Typography> */}
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container marginBlockStart={2} spacing={2}>
                        <Grid item xs={12} sx={{ marginBlockEnd: "30px" }}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>

                                    <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                        Organization Name
                                    </Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="input"
                                            variant="outlined"
                                            value={addOrganizationRequest?.name ? addOrganizationRequest?.name : ""}
                                            name="name"
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 50 }}
                                            sx={{ marginTop: "10px" }}
                                            error={Boolean(errors.name)}
                                            helperText={errors.name}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item md={6} xs={12}>
                                <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                    Organization Type
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        id="demo-simple-select"
                                        value={addOrganizationRequest?.is_direct ?  addOrganizationRequest?.is_direct : ""}
                                        onChange={handleChange}
                                        name="is_direct"
                                        sx={{ marginTop: "10px" }}
                                        
                                    >
                                        <MenuItem value="">Select Organization Type</MenuItem>
                                        <MenuItem value="yes">Direct</MenuItem>
                                        <MenuItem value="no">In-Direct</MenuItem>
                                        
                                    </Select>
                                    <FormHelperText error>
                                        {errors?.is_direct}
                                    </FormHelperText>
                                </FormControl>
                            </Grid> */}
                               
                            </Grid>
                        </Grid>

         

                    </Grid>
                
                <Grid container marginBlockStart={2} spacing={2} sx={{ marginTop: "auto",marginBottom:"10px" }}>
                  <Grid item xs={12} className="gap-10 flex-wrap">
                            <Button disabled={loading} variant="contained" type="submit">
                                {loading ? <CircularProgress sx={{ color: "#ffffff" }}  /> : localStorage.getItem("selectedId") ? "Update Organization" : "Create Organization"}
                            </Button>
                          <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                    </Grid>
                </Grid>
                </form>
              </Box>
            </Body>
        </>
    );
}
