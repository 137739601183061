import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModalCss from "../../styles/modal.module.scss";
import { makeStyles } from "@mui/styles"; // Changed import to use curly braces for makeStyles
import { bulkUploadApi } from "../../store/dealorOnBoarding/bulkUpload";
import { useDispatch } from "react-redux";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
const useStyles = makeStyles((theme) => ({
  bgLightGray: {
    backgroundColor: "rgba(213, 222, 229, 1)",
  },
  borderRadius10: {
    borderRadius: "10px", // Corrected spelling of borderRadius
  },
  p10: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  buttonsStyle: {
    backgroundColor: "#D7EDFE",
  },
  fullWidth: {
    width: "100%",
  },
}));

const BulkUpload = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const handleBulkUpload = () => {
    inputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validExtensions = [".xlsx", ".xls"];
      const isValidExtension = validExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (!isValidExtension) {
        alert("Please upload a valid Excel file (.xlsx or .xls)");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      dispatch(bulkUploadApi(formData));
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Box
            component="div"
            sx={{
              maxWidth: "600px",
              margin: "50px auto",
              textAlign: "center",
            }}
          >
            <Stack
              alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}
            >
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Stack>

            <Stack
              justifyContent="center"
              direction="column"
              gap={2}
              alignItems="center"
              fontSize={"18px"}
              boxSizing={"border-box"}
              component={"div"}
            >
              <Box
                component={"div"}
                className={`${classes.bgLightGray} ${classes.borderRadius10} ${classes.p20} ${classes.fullWidth}`}
                boxSizing={"border-box"}
              >
                <Typography
                  component={"div"}
                  sx={{
                    width: "100%",
                    fontSize: "30px",
                    color: "black",
                    lineHeight: 1.2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    height: {
                      xs: "300px",
                      md: "450px",
                    },
                  }}
                >
                  {" "}
                  <svg
                    width="35"
                    height="66"
                    viewBox="0 0 70 66"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M60.683 65.2229H9.31784C4.1801 65.2229 0 61.0428 0 55.905V44.4048H6.07195V55.905C6.07195 57.6954 7.52835 59.1509 9.31784 59.1509H60.6822C62.4725 59.1509 63.9281 57.6945 63.9281 55.905V44.4048H70V55.905C70.0009 61.0428 65.8208 65.2229 60.683 65.2229Z"
                      fill="#05141F"
                    />
                    <path
                      d="M53.5017 17.2435L37.1474 0.889106C36.5783 0.320078 35.8055 0 35.0005 0C34.1955 0 33.4227 0.320078 32.8536 0.889106L16.4993 17.2435L20.793 21.5372L31.9645 10.3657V51.0043H38.0365V10.3657L49.208 21.5372L53.5017 17.2435Z"
                      fill="#05141F"
                    />
                  </svg>
                  <TextSnippetIcon sx={{ fontSize: "100px" }} />
                  Bulk Upload
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </Typography>
              </Box>
              <Box
                component={"div"}
                width={"100%"}
                marginBlockStart={0.5}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={5}
              >
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "rgba(167, 177, 187, 1)",
                    fontSize: "20px",
                    fontWeight: 600,
                    textDecoration: "underline",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleBulkUpload}
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: 1.8,
                    boxShadow: "none",
                    backgroundColor: "#000 !important",
                    p: {
                      lg: "15px 46px",
                      md: "13px 35px",
                      sm: "10px 30px",
                      xs: "10px 30px",
                    },
                  }}
                  className={classes.buttonsStyle}
                >
                  Choose file
                </Button>
              </Box>
            </Stack>
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default BulkUpload;
