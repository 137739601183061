import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom';
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import { showToast } from '../../toast/toast';
import { deleteInventoryApi, getInventoryApi } from '../../store/inventory';
import box from '../../assets/svg/box.svg';
import { ordersApi } from '../../store/orders/orders';
import { isAllOrderValidationTrue } from '../../utils/orderItemArrayManipulation';
import DataTable from "../reusable/DataTable";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ViewArrayItemModal from '../reusable/ViewArrayItemModal';

const Order = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rowsData, setRowsData] =useState([]);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const {loading:orderLoading, error:orderError, result:orderList }  = useSelector(state => state?.orders);

    const [allOrderData, setAllOrderData] = useState([]);

    const handlePageChange = page => {
      //fetchUsers(page);
      setCurrentPage(page);
    };
  

    const columns = [
        {
          field: "main_order_id",
          headerName: "Order ID",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell component="th" scope="row" sx={{paddingLeft:"0px !important"}}>
              <Typography
                variant="h2"
                className="link2 fs-16 fw-400"
                sx={{ cursor: "pointer", }}
                onClick={() => viewOrder(params.row.id)}
              >
                {params.value}
              </Typography>
            </StyledTableCell>
          ),
        },
        {
          field: "ordered_by_user_name",
          headerName: "Dealer Name",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell sx={{paddingLeft:"0px !important"}}>
              <Typography variant="h2" className="fs-13 fw-300">
                {params.value}
              </Typography>
            </StyledTableCell>
          ),
        },
        {
          field: "cluster_name",
          headerName: "Outlet",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell sx={{paddingLeft:"0px !important"}}>
              <Typography variant="h2" className="fs-13 fw-300">
                {params.value}
              </Typography>
            </StyledTableCell>
          ),
        },
        {
          field: "no_of_products",
          headerName: "No. of Products",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell sx={{paddingLeft:"0px !important"}}>
              <Typography variant="h2" className="fs-13 fw-300">
                {(params.row.order_items?.length) || 0}
              </Typography>
            </StyledTableCell>
          ),
        },
        {
          field: "order_validation",
          headerName: "Order Validation",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell sx={{paddingLeft:"0px !important"}}>
              <Typography>
                {isAllOrderValidationTrue(params.row.order_items) ? "Done" : "Pending"}
              </Typography>
            </StyledTableCell>
          ),
        },
        {
          field: "order_date",
          headerName: "Order Date",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell sx={{paddingLeft:"0px !important"}}>
              <Typography>
                {convertToDateFormatYearWithTwoDigits(params.value)}
              </Typography>
            </StyledTableCell>
          ),
        },
        {
          field: "download_invoice",
          headerName: "Download Invoice",
          flex: 1,
          renderCell: (params) => (
            <StyledTableCell sx={{ paddingLeft: "0px !important" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => viewInvoicePopup(params.row.invoices)}
                disabled={!params.row.invoices || params.row.invoices.length === 0}
              >
                View Invoice
              </Button>
            </StyledTableCell>
          ),
        },
        
      ];
      

  const [invoiceArray, setInvoiceArray] = useState([]);
  const [open, setOpen] = useState(false);
  const viewInvoicePopup = (invoiceData) => {
    const manipulatedInvoices = invoiceData.map(invoice => ({
      ...invoice,
      files: invoice.upload_invoice, 
      upload_invoice: undefined,
    }));

    setInvoiceArray(manipulatedInvoices);
    setOpen(true);
  };

  const handleClose = () => {
      setInvoiceArray([])
      setOpen(false)
  }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleNavigate = (outletId) => {
        localStorage.setItem("selectedOutletIdToView", outletId);
        navigate(`/layout/view`);
    };
    const viewOrder = (id) => {
        localStorage.setItem("selectedOrderIdView", id);
        navigate(`/order/view/`)
    }

    const handleDownload = () => {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/api/v1/order/get_order_report/`;
    }
    
    useEffect(() => {
      setRowsData(orderList?.data)
    }, [orderList]);

    useEffect(() => {
        dispatch(ordersApi())
    },[dispatch])

    useEffect(() => {
        setAllOrderData(orderList?.data)   
    },[orderList])

    return (
      <>
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
                <Stack direction="row" alignItems="center">
                    {/* <img src={box} /> */}
                    <WidgetsOutlinedIcon/>
                    <Typography
                        color={"#05141F"}
                        fontSize={"17px"}
                        fontWeight={400}
                        marginInlineStart={"10px"}
                        fontFamily={"'KiaSignatureRegular' !important"}
                    >
                        View Order
                    </Typography>
                </Stack>
                <Button variant="contained" disabled={rowsData?.length > 0 ? false : true} onClick={handleDownload}>Download Order</Button>
            </Stack>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <DataTable  data={rowsData ? rowsData : []} columns={columns} type='order'/>


                    {/* <TableContainer className='complex-custom-table' component={Paper} sx={{ borderRadius: "10px" }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ background: "#05141F !important" }}>Order ID</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#05141F !important" }} >Dealer Name</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#05141F !important" }} >Outlet</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#05141F !important" }} >No. Of Product</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#05141F !important" }} >Order Validation</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#05141F !important" }} >Order Date</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderLoading ? (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={15} align="center">
                                                <CircularProgress color="primary" size={10} thickness={5} style={{ padding: "10px" }} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        allOrderData && allOrderData.length > 0 ? (
                                            allOrderData.map((row) => (
                                                <StyledTableRow key={row.id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        <Typography variant='h2' className='link2 fs-16 fw-400' sx={{ cursor: "pointer" }} onClick={() => viewOrder(row.id)}>
                                                            {row?.main_order_id}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Typography variant='h2' className='fs-13 fw-300'>{row?.ordered_by_user_name}</Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Typography variant='h2' className='fs-13 fw-300'>{row?.cluster_name}</Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Typography variant='h2' className='fs-13 fw-300'>{(row?.order_items)?.length || 0}</Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Typography>{isAllOrderValidationTrue(row?.order_items) ? "True" : "False"}</Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Typography>{convertToDateFormatYearWithTwoDigits(row?.order_date)}</Typography>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={15} align="center">
                                                    No Data Found!
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                    </Grid>
                </Grid>
            </Box>
        </Body>
        <ViewArrayItemModal open={open} 
                handleClose={handleClose} 
                dataArray={invoiceArray} 
                dataType='Invoice'
                />
                </>
        
    );
}
export default Order
